// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiRestore } from "@mdi/js";
import { scaleByOptions } from "constants/visualization.constants";

import {
  INITIAL_SCALE_BY_VALUE,
  updateXDASettings,
  useVisState
} from "components/vis/context";

import XdaToolbarButton from "./XdaToolbarButton";

const XdaResetSettings = () => {
  const [{ xda }, visDispatch] = useVisState();
  const currentResetZuiInstance = xda.settings.resetZuiInstance;

  const handleReset = () => {
    const nextSettings = {
      ...xda.settings,
      scaleByOption: scaleByOptions[0],
      scaleByValue: INITIAL_SCALE_BY_VALUE,
      hangWellsToTop: true,
      widthScaled: 1,
      resetZuiInstance: !currentResetZuiInstance // toggles the useEffect in the XdaViewer to fire and reset the ZUI instance
    };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaToolbarButton
      data-testid="xda-reset-settings"
      icon={<Icon path={mdiRestore} size={1.5} />}
      tooltipText="Reset View"
      overflowLabel="Reset"
      onToggle={() => handleReset()}
      active={false}
    />
  );
};

export default XdaResetSettings;
