import { ProductKind } from "constants/chart.constants";

// To change chart title and axis labels go to ProductType.cs in eva-chart-svc
export function getShortenedProductName(
  productKind,
  product: string,
  rawLabel?: string,
  salesLabel?: string,
  inletRateLabel?: string,
  outletRateLabel?: string
): string {
  const productDict = {
    [`C2 Yields ${rawLabel}`]: "C2",
    [`C3 Yields ${rawLabel}`]: "C3",
    [`C4 Yields ${rawLabel}`]: "C4",
    [`C5+ Yields ${rawLabel}`]: "C5+",
    [`C2 Yields ${salesLabel}`]: "C2",
    [`C3 Yields ${salesLabel}`]: "C3",
    [`C4 Yields ${salesLabel}`]: "C4",
    [`C5+ Yields ${salesLabel}`]: "C5+",
    [`Liquids Yields ${salesLabel}`]: "Sales Liquids",
    [`Liquids Yields ${rawLabel}`]: "Sales Liquids",
    [`Oil, Cond, C5+ Yields ${rawLabel}`]: "Oil, Cond, C5+",
    [`Oil, Cond, C5+ Yields ${salesLabel}`]: "Oil, Cond, C5+",
    [`NGL Mix Yields ${salesLabel}`]: "NGL Mix",
    [`NGL Mix Yields ${rawLabel}`]: "NGL Mix",
    [`NGL₂₃₄ Yields ${salesLabel}`]: "NGL₂₃₄",
    [`NGL₂₃₄ Yields ${rawLabel}`]: "NGL₂₃₄",
    [`Sulphur Yields ${salesLabel}`]: "Sulphur",
    [`Sulphur Yields ${rawLabel}`]: "Sulphur",
    [`GP Water Yields ${salesLabel}`]: "GP Water",
    [`GP Water Yields ${rawLabel}`]: "GP Water",
    [`CO₂ Yields ${salesLabel}`]: "CO2",
    [`CO₂ Yields ${rawLabel}`]: "CO2",
    [`Fuel ${inletRateLabel}`]: "Fuel",
    [`Fuel ${outletRateLabel}`]: "Fuel",
    [`Flared ${inletRateLabel}`]: "Flared",
    [`Flared ${outletRateLabel}`]: "Flared",
    [`Vented ${inletRateLabel}`]: "Vented",
    [`Vented ${outletRateLabel}`]: "Vented",
    [`FFV ${inletRateLabel}`]: "FFV",
    [`FFV ${outletRateLabel}`]: "FFV",
    [`Liquids Shrinkage`]: "Liquids Shr.",
    [`Acid Gas Shrinkage`]: "AcGas Shr.",
    [`Acid Gas Disposition`]: "AcGas Disp.",
    [`Liquids Shrinkage ${inletRateLabel}`]: "Liquids Shr.",
    [`Liquids Shrinkage ${outletRateLabel}`]: "Liquids Shr.",
    [`Acid Gas Shrinkage ${inletRateLabel}`]: "AcGas Shr.",
    [`Acid Gas Shrinkage ${outletRateLabel}`]: "AcGas Shr.",
    [`Acid Gas Disposition ${inletRateLabel}`]: "AcGas Disp.",
    [`Acid Gas Disposition ${outletRateLabel}`]: "AcGas Disp.",
    [`Total Shrinkage Inlet`]: "Total Shr.",
    [`Total Shrinkage Outlet`]: "Total Shr.",
    BOE: "Wellhead BOE",
    // For the summary tab we want to use a shorter label.
    ...(productKind === ProductKind["Summary"] && {
      [`Oil, Cond, C5+ Yields ${rawLabel}`]: "(OCC)GR"
    })
  };

  if (product in productDict) {
    return productDict[product];
  } else {
    return product;
  }
}
