import { toast } from "react-toastify";

import { getDefaultRateUnits, productStringToProductTypeEnum } from "utils";
import { convert } from "utils/units/unitConversion";

import { ForecastConstant } from "models/UserArpsModel";

/**
 * Formats an array of forecast constants by converting their values to the default rate units.
 * If a constant has a unit, it converts the value to the default ratio unit.
 * If a constant does not have a unit, it assumes the value is in the database unit and converts it to the default ratio unit.
 *
 * @param {ForecastConstant[]} constants - The array of forecast constants to format.
 * @returns {ForecastConstant[]} - The array of formatted forecast constants with values converted to default rate units.
 *
 * @throws Will display an error toast if there is an error converting a constant to the default rate unit.
 */
export function convertForecastConstantUnits(
  constants: ForecastConstant[]
): ForecastConstant[] {
  const constantsWithUnits = [];
  for (const constant of constants) {
    const constantWithUnit = { ...constant };

    try {
      constantWithUnit.value = constant.unit
        ? convert(
            constantWithUnit.value,
            constantWithUnit.unit,
            getDefaultRateUnits(productStringToProductTypeEnum(constant.product))
          )
        : constantWithUnit.value * (constant.product === "WOR" ? 1 : 1000);
    } catch (err) {
      toast.error(`Error converting constant ${constant.product} to default rate unit`);
    }
    constantWithUnit.unit = getDefaultRateUnits(
      productStringToProductTypeEnum(constant.product)
    );
    constantsWithUnits.push(constantWithUnit);
  }
  return constantsWithUnits;
}
