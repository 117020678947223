import { useUser } from "./index";

/**
 * List of available beta features.
 */
export type BetaFeatures =
  | "Developer Api Tokens"
  | "3DV Columns"
  | "Org Geo Fields"
  | "Chart Reference Lines"
  | "Geo Model Loader"
  | "Plant Liquids"
  | "Facility"
  | "Forecast Compare"
  | "File Management"
  | "Prod Data Import Files"
  | "Midstream Export"
  | "Pad Scenario"
  | "App Tools"
  | "Forecasting"
  | "Cutoff Geo Models"
  | "Facility Volumes";

/**
 * Custom hook to check if a user has access to beta features.
 * Uses the `useUser` hook internally to get the user data.
 * @returns {Object} An object with a `hasFeature` method to check if a feature is enabled.
 */
export default function useBetaFeatures() {
  const user = useUser();

  return {
    hasFeature: (feature: BetaFeatures): boolean => {
      return user?.user?.organization?.betaFeatures?.includes(feature);
    }
  };
}
