import { Icon } from "@mdi/react";

import { mdiClose } from "@mdi/js";
import { Button } from "antd";

import { Tooltip } from "components/base";

import { useForecastContext, useForecastDispatch } from "./context";

interface ForecastFolderRemoveButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodeToRemove: any;
}

export default function ForecastFolderRemoveButton(
  props: ForecastFolderRemoveButtonProps
) {
  const { nodeToRemove } = props;

  const { temporaryForecastFolderList, forecastFolderNameFieldMap, nameChangeErrorId } =
    useForecastContext();

  const forecastDispatch = useForecastDispatch();

  function removeTemporaryFolder(id: string) {
    const temporaryForecastFolderListCopy = temporaryForecastFolderList.filter(
      (folder) => folder.temporaryId !== id
    );

    const forecastFolderNameFieldMapCopy = { ...forecastFolderNameFieldMap };
    delete forecastFolderNameFieldMapCopy[id];

    forecastDispatch({
      payload: {
        forecastFolderNameFieldMap: forecastFolderNameFieldMapCopy,
        temporaryForecastFolderList: temporaryForecastFolderListCopy
      }
    });

    const hasNameChangeError = nameChangeErrorId === id;
    if (hasNameChangeError) {
      forecastDispatch({
        payload: { nameChangeErrorId: null, nameChangeError: null }
      });
    }
  }

  return (
    <Tooltip title="Remove Folder">
      <Button
        className={"small-remove-button"}
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          removeTemporaryFolder(nodeToRemove.temporaryId);
        }}
        icon={<Icon path={mdiClose} size={1} />}
      />
    </Tooltip>
  );
}
