// eslint-disable-next-line
import Icon from "@mdi/react";

import { Business, Group, Person } from "@material-ui/icons";
import { mdiImageFilterHdr } from "@mdi/js";
import styled from "styled-components/macro";

import { ProjectModel } from "api/project";

const StyledIcon = styled(Icon)`
  cursor: default;
  color: #a2aaad;
  top: 3px;
  right: 3px;
`;

const PROJECT_TYPE_TO_ICON = {
  Organization: Business,
  Shared: Group,
  McDaniel: () => (
    <StyledIcon path={mdiImageFilterHdr} className="activity-action-icon" size={1} />
  ),
  default: Person
};

const ICON_STYLE = {
  color: "#A2AAAD",
  fontSize: "18px",
  top: "4px",
  right: "2px"
};

const StyledSpan = styled.span`
  padding-left: 3px;
`;

interface ProjectOption {
  label: JSX.Element;
  value: string;
}

const createProjectOption = (project: ProjectModel): ProjectOption => {
  if (!project) return { label: <></>, value: "" };

  const IconComponent =
    PROJECT_TYPE_TO_ICON[project.projectType] || PROJECT_TYPE_TO_ICON.default;

  const label = (
    <StyledSpan key={project.projectId}>
      <IconComponent style={ICON_STYLE} />
      {`${project.projectName}`}
    </StyledSpan>
  );

  return {
    label: label,
    value: project.projectId
  };
};

export const getFilteredProjectOptions = (
  projects: ProjectModel[],
  selectedGroups: string[]
): ProjectOption[] => {
  if (!projects) return [];
  if (selectedGroups.length === 0) return projects.map(createProjectOption);

  const selectedGroupsSet = new Set(selectedGroups.map((g) => g.toLowerCase()));
  const filteredProjects = projects.filter((project) => {
    const projectGroups = project.groups || [];
    for (const group of projectGroups) {
      if (selectedGroupsSet.has(group.toLowerCase())) return true;
    }
    return false;
  });

  return filteredProjects.map(createProjectOption);
};
