import IconComponent from "@mdi/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Error } from "@material-ui/icons";
import { mdiContentCopy } from "@mdi/js";
import { Tabs } from "antd";
import { ALL_PRODUCT_TYPES, ThroughputProduct } from "constants/chart.constants";
import { groupBy } from "lodash";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useCategories, useProperty } from "hooks";
import useBetaFeatures from "hooks/useBetaFeatures";

import { IGroupByListColumn, Uwi } from "models";
import { EntityKind } from "models/entityKind";

import { getMidstreamValue } from "components/groupBy/hooks/useGroupedProductOptions";
import { IconSpinner } from "components/icons";
import { useUserSettings } from "components/user/hooks";

import WellContributionTable from "./WellContributionTable";
import { setActiveGroup, useWellDetails } from "./context";
import { useWellDetailsData } from "./hooks";

interface WellCardInput {
  entityKind: EntityKind;
}

export default function WellCard({ entityKind = EntityKind.Well }: WellCardInput) {
  // state
  const [tabs, setTabs] = useState([]);
  const [activeKey, setActiveKey] = useState("Header");
  const { hasFeature } = useBetaFeatures();

  // custom hooks
  const {
    selectedWell,
    selectedFacility,
    headerProperties,
    facilityHeaderProperties,
    skippedGroups,
    isLoading,
    error,
    typeLogMessage
  } = useWellDetailsData({ entityKind });
  const { categories, facilityCategories } = useCategories();
  const [state, dispatch] = useWellDetails();
  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);
  const userSettings = useUserSettings();
  const isMidstreamDataSourceEnabled = userSettings?.midstreamSettings?.enabled;
  const headers =
    entityKind === EntityKind.Well ? headerProperties : facilityHeaderProperties;

  const isWell = entityKind === EntityKind.Well;
  const isFacility = entityKind === EntityKind.Facility;
  const message = isWell
    ? "Please select a well on the map"
    : "Please select a facility on the map";

  // Effects and callbacks
  useEffect(() => {
    if (!isWell) return;
    if (!categories) return;
    const typeLogTab = {
      name: "Type Log",
      hexColor: "#57B586",
      sortOrder: 101,
      property: "",
      columns: [],
      id: "TypeLog"
    };
    const categoriesWithTypeLog = [...categories, typeLogTab];

    const newTabs = categoriesWithTypeLog.reduce((list, group) => {
      if (!skippedGroups.includes(group.name)) {
        if (state.loadedGroups.includes(group.name) || typeLogMessage) {
          const children = [];
          const subgroups = groupBy(group.columns, "subgroup");
          for (const [subgroup, subgroupColumns] of Object.entries(subgroups)) {
            const columns = subgroupColumns as IGroupByListColumn[];
            children.push(<WellDetailsGroupTitle>{subgroup}</WellDetailsGroupTitle>);
            children.push(
              <WellDetailsContainer>
                {columns
                  .filter((c) => !headerProperties.includes(c.property))
                  .map((column) => {
                    return (
                      <WellCardDataValue
                        key={column.property}
                        propertyName={column.property}
                        entityKind={entityKind}
                      />
                    );
                  })}
              </WellDetailsContainer>
            );
          }
          if (group.name === "Type Log") {
            if (!typeLogMessage) {
              // create link to pdf
              const pdfBlob = new Blob([state?.wellDetails[0]], {
                type: "application/pdf"
              });
              const pdfObjectUrl = URL.createObjectURL(pdfBlob);
              children.push(
                <object
                  data={pdfObjectUrl}
                  type="application/pdf"
                  width="100%"
                  height="100%">
                  <p>
                    PDF settings may be configured to by download PDFs on your browser, if
                    so, navigate to the PDF documents settings to open PDFs in browser.
                    You can download the PDF{" "}
                    <a
                      href={pdfObjectUrl}
                      target="_blank"
                      download={`${selectedWell}.pdf`}
                      rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </p>
                </object>
              );
            } else {
              children.push(typeLogMessage);
            }
          }

          list.push({
            label: group.name,
            key: group.name,
            children: <TabContentContainer>{children}</TabContentContainer>
          });
        } else if (selectedWell) {
          list.push({
            label: group.name,
            key: group.name,
            children: (
              <TabLoadingContentContainer>
                <StyledSpinner />{" "}
                {`Loading ${group.name} for ${new Uwi().toFormatted(selectedWell)}`}
              </TabLoadingContentContainer>
            )
          });
        }
      }

      return list;
    }, []);
    setActiveKey(state.currentGroup);
    setTabs(newTabs);
  }, [
    categories,
    isWell,
    state.loadedGroups,
    skippedGroups,
    headerProperties,
    selectedWell,
    typeLogMessage
  ]);

  useEffect(() => {
    if (!isFacility) return;
    if (!facilityCategories) return;
    const facilityCategoriesWithWellContributions = [
      ...facilityCategories,
      {
        name: "Wells",
        hexColor: "#57B586",
        sortOrder: 101,
        property: "",
        columns: [],
        id: "WellContributions"
      }
    ];
    const gasProducts = ThroughputProduct.find(
      (product) => product.name === "Gas"
    ).items.map(getMidstreamValue);
    const performanceColumns = facilityCategoriesWithWellContributions.find(
      (category) => category.name === "Performance"
    )?.columns;
    const gasProductsColumns = performanceColumns?.filter((column) =>
      gasProducts.includes(column.product)
    );
    const newTabs = facilityCategoriesWithWellContributions.reduce((list, group) => {
      if (!skippedGroups.includes(group.name)) {
        let groupName = group.name;
        if (groupName == "Licensed Volumes") {
          groupName = "Volumes";
        }
        if (state.loadedGroups.includes(group.name)) {
          const isVolumesGroupName = groupName === "Volumes";
          const children = [];
          const subgroups = groupBy(group.columns, "subgroup");
          const performanceSubgroups = [];
          if (isVolumesGroupName) {
            gasProducts.forEach((product) => {
              let productName = product;
              if (product === ALL_PRODUCT_TYPES.GasInlet.key) {
                productName = ALL_PRODUCT_TYPES.GasInlet.label;
              }
              if (product === ALL_PRODUCT_TYPES.GasOutlet.key) {
                productName = ALL_PRODUCT_TYPES.GasOutlet.label;
              }
              performanceSubgroups[productName] = gasProductsColumns.filter(
                (column) => column.product === product
              );
            });
          }
          for (const [subgroup, subgroupColumns] of Object.entries(subgroups)) {
            const columns = subgroupColumns as IGroupByListColumn[];
            let subGroupName = subgroup;
            if (subGroupName == "General" && isVolumesGroupName) {
              subGroupName = "Licensed Volumes";
            }
            children.push(<WellDetailsGroupTitle>{subGroupName}</WellDetailsGroupTitle>);
            children.push(
              <WellDetailsContainer>
                {columns.map((column) => {
                  return (
                    <WellCardDataValue
                      key={column.property}
                      propertyName={column.property}
                      entityKind={entityKind}
                    />
                  );
                })}
              </WellDetailsContainer>
            );
          }
          if (isVolumesGroupName) {
            children.push(<WellDetailsGroupTitle>{"Performance"}</WellDetailsGroupTitle>);
            for (const [productIndex, [product, columns]] of Object.entries(
              performanceSubgroups
            ).entries()) {
              const isLastProduct =
                productIndex === Object.entries(performanceSubgroups).length - 1;
              const element = (
                <>
                  <WellDetailsGroupTitle>{product}</WellDetailsGroupTitle>
                  <PerformanceDetailsContainer
                    className={`${isLastProduct ? "last-item" : ""}`}>
                    {columns.map((column) => (
                      <WellCardDataValue
                        key={column.property}
                        propertyName={column.property}
                        entityKind={entityKind}
                      />
                    ))}
                  </PerformanceDetailsContainer>
                </>
              );
              children.push(element);
            }
          }
          if (groupName === "Wells") {
            children.push(
              <div>
                <WellContributionTable />
              </div>
            );
          }

          list.push({
            label: groupName,
            key: group.name,
            children: <TabContentContainer>{children}</TabContentContainer>
          });
        } else if (selectedFacility) {
          list.push({
            label: groupName,
            key: group.name,
            children: (
              <TabLoadingContentContainer>
                <StyledSpinner />{" "}
                {`Loading ${groupName} for ${new Uwi().toFormatted(selectedFacility)}`}
              </TabLoadingContentContainer>
            )
          });
        }
      }

      if (hasFeature("Facility Volumes")) {
        // remove item that has label === "Wells"
        list = list.filter((item) => item.label !== "Wells");
      }

      return list;
    }, []);

    setActiveKey(state.currentGroup);
    setTabs(newTabs);
  }, [
    facilityCategories,
    state.loadedGroups,
    isFacility,
    skippedGroups,
    facilityHeaderProperties,
    selectedFacility
  ]);

  const onTabChange = useCallback(
    (activeKey: string) => {
      setActiveGroup(dispatch, activeKey);
      setActiveKey(activeKey);
    },
    [dispatch]
  );

  return (
    <WellCardContainer>
      {((isLoading && state.loadedGroups?.length === 0) ||
        error ||
        (!selectedWell && isWell) ||
        (!selectedFacility && isFacility)) && (
        <OverlayContainer>
          <OverlayMessages>
            {isLoading && state.loadedGroups?.length === 0 && (
              <div data-testid="loading-spinner">
                <StyledSpinner />
              </div>
            )}
            {error && (
              <ErrorContainer data-testid="error-container">
                <Error style={{ top: 3 }} /> {error}
              </ErrorContainer>
            )}
            {((!selectedWell && isWell) || (!selectedFacility && isFacility)) && (
              <NoSelectedWellContainer data-testid={"no-well-container"}>
                {(() => {
                  if (
                    (activeEntities.includes(EntityKind.Facility) && isFacility) ||
                    (activeEntities.includes(EntityKind.Well) && isWell)
                  ) {
                    return message;
                  }
                  if (isWell && !activeEntities.includes(EntityKind.Well)) {
                    return "Wells are hidden";
                  }
                  if (isFacility) {
                    return isMidstreamDataSourceEnabled
                      ? "Midstream is hidden"
                      : "Midstream is disabled";
                  }
                })()}
              </NoSelectedWellContainer>
            )}
          </OverlayMessages>
        </OverlayContainer>
      )}
      <HeaderDetailsContainer>
        {headers &&
          headers.map((propertyName, i) => {
            return (
              <WellCardDataValue
                key={i}
                propertyName={propertyName}
                primary={i === 0}
                entityKind={entityKind}
              />
            );
          })}
      </HeaderDetailsContainer>
      <TabbedInfoContainer>
        <Tabs activeKey={activeKey} type="card" items={tabs} onChange={onTabChange} />
      </TabbedInfoContainer>
    </WellCardContainer>
  );
}

interface WellCardDataValueInputModel {
  propertyName: string;
  primary?: boolean;
  copyValue?: boolean;
  entityKind?: EntityKind;
}

export function WellCardDataValue({
  propertyName = "",
  primary = false,
  copyValue = true,
  entityKind = EntityKind.Facility
}: WellCardDataValueInputModel) {
  // custom hooks
  const property = useProperty(propertyName, false, "", entityKind);
  const [wellDetails] = useWellDetails();

  const wellData = wellDetails.wellDetails;
  const isDateGroup = property?.group === "Dates";
  const title =
    property?.group === "Performance"
      ? property?.lightContextTitle
      : property?.fullContextTitle;
  const alignLeft =
    entityKind === EntityKind.Facility &&
    (property?.group === "Header" || property?.group === "Dates");

  const copyUwid = useCallback(() => {
    if (!property || !(propertyName in wellData)) return;

    const value = wellData[propertyName];
    if (typeof value === "string") {
      navigator.clipboard.writeText(value);
    }
    toast.success(`${property.fullContextTitle} copied to clipboard`);
  }, [property, propertyName, wellData]);

  if (!property || !(propertyName in wellData)) return null;

  if (primary) {
    return (
      <WellDetailPrimaryCell>
        {wellData[propertyName]}
        <WellDetailValueCopyButton onClick={copyUwid}>
          <IconComponent path={mdiContentCopy} size={1} />
        </WellDetailValueCopyButton>
      </WellDetailPrimaryCell>
    );
  }

  return (
    <WellDetailCell primary={primary}>
      <WellDetailTitle isDateGroup={isDateGroup} primary={primary}>
        {title}
      </WellDetailTitle>
      <WellDetailValue
        primary={primary}
        isDateGroup={isDateGroup}
        isNumber={property.dataType === "Integer" || property.dataType === "Number"}
        alignLeft={alignLeft}>
        {wellData[propertyName]}
        {copyValue && wellData[propertyName] && (
          <WellDetailValueCopyButton onClick={copyUwid}>
            <IconComponent path={mdiContentCopy} size={1} />
          </WellDetailValueCopyButton>
        )}
      </WellDetailValue>
    </WellDetailCell>
  );
}

const WellCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 1;
`;
const OverlayMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: inherit;
`;
const HeaderDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 18px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;
const WellDetailsGroupTitle = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  color: var(--color-text);
  font-weight: 800;
`;
const WellDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 18px;
  padding: 0px 10px;
  border-bottom: 1px solid #ccc;
`;

const PerformanceDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 18px;
  padding: 0 10px;
  border-bottom: ${(props) =>
    props.className && props.className.includes("last-item") ? "1px solid #ccc" : "none"};
`;

const WellDetailCell = styled.div`
  display: flex;
`;
const WellDetailTitle = styled.div`
  width: ${(props) => (props.isDateGroup ? "65%" : "50%")};
  color: var(--color-action-button);
`;
const WellDetailValueCopyButton = styled.span`
  cursor: pointer;
  color: var(--color-action-button);
  opacity: 0;

  &:hover {
    color: var(--color-primary);
  }
`;
const WellDetailPrimaryCell = styled.div`
  display: flex;
  gap: 8px;
  grid-column: 1 / -1;
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 800;

  &:hover > ${WellDetailValueCopyButton} {
    opacity: 1;
  }
`;
const WellDetailValue = styled.div`
  font-family: var(--fontMono);
  width: ${(props) => (props.isDateGroup ? "35%" : "50%")};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.alignLeft ? "left" : props.isNumber ? "right" : "left"};
  gap: 8px;
  color: ${(props) => (props.primary ? "var(--color-primary)" : "var(--color-text)")};
  font-weight: ${(props) => (props.primary ? "800" : "auto")};

  &:hover > ${WellDetailValueCopyButton} {
    opacity: 1;
  }
`;

const TabbedInfoContainer = styled.div`
  height: 100%;
  overflow: hidden;

  .ant-tabs {
    height: 100%;
    overflow: hidden;

    .ant-tabs-content {
      height: 100%;
      overflow: hidden;

      .ant-tabs-tabpane {
        height: 100%;
        overflow: hidden;
      }
    }
  }
`;
const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
const TabLoadingContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
`;
const ErrorContainer = styled.div`
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-danger);
  border-radius: 5px;
  padding: 2px 20px;
  margin: 0 20px;
`;
const StyledSpinner = styled(IconSpinner)`
  margin: auto auto;
`;
const NoSelectedWellContainer = styled.div`
  color: var(--color-white);
  background-color: var(--color-text);
  border-radius: 5px;
  padding: 2px 20px;
  margin: 0 20px;
`;
