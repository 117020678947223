import axios from "axios";

import { EntityKind } from "../models/entityKind";
import { Result } from "./dataSource";

const ROOT_ENDPOINT = process.env.REACT_APP_DATA_ROOT;
const DATA_ENDPOINT = `${ROOT_ENDPOINT}/api/v1/data`;
export const USER_COLUMN_ENDPOINT = `${ROOT_ENDPOINT}/api/v1/user-defined-column`;

type DataQueryParamsT = {
  pdenSource: number;
  pageSize: number;
  pageIndex: number;
  sortColumn?: string;
  sortDirection?: string;
  entityKind?: string;
};

export function fetchData(filterId: string, fields: string[], params: DataQueryParamsT) {
  const body = {
    filterId,
    fields,
    entityKind: params.entityKind,
    outputFormatType: "JSON"
  };
  return axios.post(DATA_ENDPOINT, body, { params }).then((res) => res.data);
}

export async function fetchTypeLogData() {
  return axios
    .get(`${DATA_ENDPOINT}/typeLog`)
    .then((res) => res.data)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Error fetching type log data:", error);
    });
}

export interface ColumnModel {
  name: string;
  group: string;
  id?: string;
  note?: string;
  units: string;
  data: { [name: string]: string };
  pythonScript: string;
  entityKind?: EntityKind;
}

export interface UploadExcelResult {
  columns: ColumnModel[];
  uwis: string[];
}

export interface ExportDataRequest {
  filterId: string;
  properties: string[];
  format: "CSV" | "Excel";
  forecastSource: string;
  pdenSource?: number;
  syncJob?: string;
  entityKind?: EntityKind;
}

export interface InfoRequest {
  filterId?: string;
  uniqueId: string;
  aggregateProperty: string;
  aggregate: string;
  forecastFolder?: string;
  pdenSource?: number;
}

export interface WellDetailCardRequest {
  uwid: string;
  group?: string;
  fields: string[];
  entityKind?: EntityKind;
}
export interface WellDetailCardData {
  [name: string]: string | Blob;
}

export interface WellDetailCardDataGroup {
  group: string;
  wellData: WellDetailCardData;
}
export interface WellCardTypeLogPdf {
  group: string;
  wellData: Blob;
}

export async function postPythonColumn(
  data: ColumnModel,
  isOrg: boolean
): Promise<Result<boolean>> {
  const response = await axios.post(
    `${USER_COLUMN_ENDPOINT}/python?isOrg=${isOrg ? "true" : "false"}`,
    data
  );
  return response.data;
}

export async function postUserDefinedColumn(
  data: ColumnModel[],
  isOrg: boolean
): Promise<Result<boolean>> {
  const response = await axios.post(
    `${USER_COLUMN_ENDPOINT}?isOrg=${isOrg ? "true" : "false"}`,
    data
  );
  return response.data;
}

export async function uploadTemplateFile(file, entityKind): Promise<UploadExcelResult> {
  const formData = new FormData();

  formData.append("file", file);
  const response = await axios.post<UploadExcelResult>(
    `${USER_COLUMN_ENDPOINT}/upload?entityKind=${entityKind}`,
    formData
  );
  return response.data;
}

export async function deleteUserDefinedColumn(
  columnIds: string[],
  isOrg: boolean,
  entityKind: EntityKind = EntityKind.Well
): Promise<boolean> {
  const response = await axios.delete<boolean>(
    `${USER_COLUMN_ENDPOINT}/?isOrg=${
      isOrg ? "true" : "false"
    }&entityKind=${entityKind.toString()}`,
    {
      data: columnIds
    }
  );
  return response.data;
}

export async function updateUserDefinedColumnOrder(
  data,
  isOrg: boolean,
  entityKind: EntityKind = EntityKind.Well
): Promise<Result<boolean>> {
  const response = await axios.post(
    `${USER_COLUMN_ENDPOINT}/order/?isOrg=${
      isOrg ? "true" : "false"
    }&entityKind=${entityKind}`,
    data
  );
  return response.data;
}

export async function editUserDefinedColumn(
  data: ColumnModel,
  isOrg: boolean,
  entityKind: EntityKind = EntityKind.Well
): Promise<Result<boolean>> {
  const response = await axios.put(
    `${USER_COLUMN_ENDPOINT}/?isOrg=${isOrg ? "true" : "false"}&entityKind=${entityKind}`,
    data
  );
  return response.data;
}

export async function downloadColumnTemplate(
  filterId: string,
  entityKind: EntityKind
): Promise<Result<string>> {
  const response = await axios.get(
    `${USER_COLUMN_ENDPOINT}/export?filterId=${filterId}&exportFormat=Excel&entityKind=${entityKind}`,
    {
      responseType: "blob"
    }
  );
  if (response.status === 200) {
    const encodedUri = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = encodedUri;
    link.download = "eva-field-template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return {
      ok: true,
      value: ""
    };
  }
  return {
    ok: false,
    error: response.data
  };
}

export async function exportData(request: ExportDataRequest) {
  return await axios.post(
    `${DATA_ENDPOINT}/export?exportFormat=${request.format}&forecastSource=${request.forecastSource}&pdenSource=${request.pdenSource}&syncJob=${request.syncJob}`,
    {
      filterId: request.filterId,
      fields: request.properties,
      outputFormatType: "JSON",
      authToken: "",
      entityKind: request.entityKind
    },
    {
      responseType: "blob"
    }
  );
}

export async function getSingleWellInfo(request: InfoRequest) {
  return await axios.post(`${DATA_ENDPOINT}/single-well/info`, request);
}

export async function getWellDetailCard(request: WellDetailCardRequest) {
  if (request.group === "Type Log") {
    return await axios.post(
      `${DATA_ENDPOINT}/single/${request.uwid}`,
      {
        fields: request.fields
      },
      {
        responseType: "arraybuffer"
      }
    );
  }
  return await axios.post(`${DATA_ENDPOINT}/single/${request.uwid}`, {
    fields: request.fields,
    outputFormatType: "JSON",
    entityKind: request.entityKind
  });
}

export async function updateGroupOrder(data, isOrg: boolean): Promise<Result<boolean>> {
  const response = await axios.post(
    `${USER_COLUMN_ENDPOINT}/groupOrder/?isOrg=${isOrg ? "true" : "false"}`,
    data
  );
  return response.data;
}

export async function getGroupOrder(isOrg: boolean) {
  const response = await axios.get(
    `${USER_COLUMN_ENDPOINT}/groupOrder/?isOrg=${isOrg ? "true" : "false"}`
  );
  return response.data;
}

export async function getValidWells(wells: string[]): Promise<string[]> {
  const endpoint = `${ROOT_ENDPOINT}/api/v1/uwi`;
  const response = await axios.post<string[]>(endpoint, wells);
  return response.data;
}

export interface GeomBinModel {
  id: string;
  group: string;
  name: string;
  isDeleted: boolean;
  nodes: GeomBinNodeModel[];
}

export interface GeomBinNodeModel {
  id: string;
  binId?: string;
  name: string;
  order: number;
  geom: string;
  isDeleted: boolean;
  color: string;
  strokeColor: string;
  opacity: number;
  thickness: number;
}

export async function postGeomBin(
  data: GeomBinModel,
  isOrg: boolean
): Promise<Result<boolean>> {
  const response = await axios.post(
    `${USER_COLUMN_ENDPOINT}/geombin?isOrg=${isOrg ? "true" : "false"}`,
    data
  );
  return response.data;
}

export async function deleteGeomBins(ids: string[]): Promise<boolean> {
  const response = await axios.delete<boolean>(`${USER_COLUMN_ENDPOINT}/geombin`, {
    data: ids
  });
  return response.data;
}

export async function updateGeomBinOrder(data): Promise<Result<boolean>> {
  const response = await axios.post(`${USER_COLUMN_ENDPOINT}/geombin/order`, data);
  return response.data;
}

export async function getGeomBin(binId: string): Promise<Result<boolean>> {
  const response = await axios.get(`${USER_COLUMN_ENDPOINT}/geombin/${binId}`);
  return response.data;
}

export async function updateGeomBinGroupOrder(
  data,
  isOrg: boolean
): Promise<Result<boolean>> {
  const response = await axios.post(
    `${USER_COLUMN_ENDPOINT}/geombin/groupOrder/?isOrg=${isOrg ? "true" : "false"}`,
    data
  );
  return response.data;
}

export async function getGeomBinGroupOrder(isOrg: boolean) {
  const response = await axios.get(
    `${USER_COLUMN_ENDPOINT}/geombin/groupOrder/?isOrg=${isOrg ? "true" : "false"}`
  );
  return response.data;
}
