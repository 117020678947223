import React, { useRef } from "react";

import { useAlertClickOutside } from "./hooks/useAlertClickOutside";

interface AlertClickOutsideProps {
  children?: React.ReactNode;
  onOutsideClick: (event: MouseEvent) => Promise<void>;
}

const AlertClickOutside: React.FC<AlertClickOutsideProps> = ({
  onOutsideClick,
  children
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useAlertClickOutside({
    ref: wrapperRef,
    onOutsideClick
  });

  return <div ref={wrapperRef}>{children}</div>;
};

export default AlertClickOutside;
