import axios from "axios";

const ROOT_ENDPOINT = process.env.REACT_APP_DATA_ROOT;
const DATA_ENDPOINT = `${ROOT_ENDPOINT}/api/v1/data`;

export async function getDynamicBinSettings(p10: number, p90: number) {
  const API_URL = `${DATA_ENDPOINT}/dynamic-bin-size/${p10}/${p90}`;

  try {
    const response = await axios.get(API_URL);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Failed to fetch bin size: ${response.statusText}`);
    }
  } catch (error) {
    return null;
  }
}
