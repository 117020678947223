import { LegendItemModel } from "models";
import { IChartSeries, IChartSettings } from "models/chart";
import { EntityKind } from "models/entityKind";
import { ChartSeries } from "models/model";

import { MinMaxT } from "../ChartWrapper";
import { StateT } from "../context";
import { createLegendItem } from "../utils";
import { BaseChart } from "./baseChart";

export class TotalRateDate extends BaseChart {
  getSeriesStackGroup(item): string {
    return item?.label !== "Well Count" ? "stack" : "";
  }

  getDefaultAxisMinMax(entityKind?: EntityKind): MinMaxT {
    return {
      xMin: entityKind === EntityKind.Well ? "2000-01" : null,
      xMax: entityKind === EntityKind.Well ? "2050-01" : null,
      yMin: null,
      yMax: null
    };
  }

  getDefaultAxis(
    sessionMinMax?: MinMaxT,
    lock?: number,
    isSeriesYExceedingMaxY?: boolean,
    entityKind?: EntityKind
  ): MinMaxT {
    const defaultMinMax = this.getDefaultAxisMinMax(entityKind);
    if (!sessionMinMax) {
      return defaultMinMax;
    }
    const yMax = !isNaN(parseFloat(sessionMinMax?.yMax?.toString()))
      ? parseFloat(sessionMinMax?.yMax?.toString())
      : defaultMinMax.yMax;
    const yMin = !isNaN(parseFloat(sessionMinMax?.yMin?.toString()))
      ? parseFloat(sessionMinMax?.yMin?.toString())
      : defaultMinMax.yMin;
    const xMax = sessionMinMax?.xMax ?? defaultMinMax.xMax;
    const xMin = sessionMinMax?.xMin ?? defaultMinMax.xMin;
    return {
      xMin,
      xMax,
      yMin,
      yMax
    };
  }

  applySeriesFiltering(series: IChartSeries[], settings: IChartSettings): IChartSeries[] {
    if (!settings.showWellCount) {
      return series.filter((item) => item.label !== "Well Count");
    }
    return series;
  }

  getCustomLegendTitle(state: StateT): string {
    const settings = state.settings;
    if (settings.otherProducts && settings.otherProducts.length > 0) {
      return "Products";
    }
    return null;
  }

  getCustomLegendItems(
    series: ChartSeries[],
    settings: IChartSettings,
    entityKind: EntityKind
  ): LegendItemModel[] {
    if (!series) {
      return [];
    }

    return series
      .filter((item) => !item.isForecast)
      .filter((item) => settings.showWellCount || item.label !== "Well Count")
      .map((item) => {
        const legendItem = createLegendItem(
          item.label,
          true,
          item.style?.hexColor ?? "black" //default to black if there's no color
        );
        legendItem.count = 1;
        if (item.label === "Well Count") {
          if (entityKind === EntityKind.Facility) {
            legendItem.title = "Facility Count";
          }
          legendItem.color = "black";
        }
        return legendItem;
      });
  }
}
