import { UseMutationResult, useMutation, useQuery } from "react-query";

import axios from "axios";

import { ApiResult } from "./apiResult";

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;
export interface InitializeTimelineResultModel {
  timelineId: string;
  minTime: Date;
  maxTime: Date;
  maxIndex: number;
  maxCum: number;
}

export interface InitializeTimelineResult {
  mutation: UseMutationResult<InitializeTimelineResultModel | undefined>;
}
export interface TimelineMutationInput {
  filterId: string;
  txnId: string;
  product: string;
}
export function useInitializeTimeline(
  // eslint-disable-next-line no-unused-vars
  onSuccess: (data: InitializeTimelineResultModel) => void
): InitializeTimelineResult {
  const mutation = useMutation(
    async ({ filterId, txnId, product }: TimelineMutationInput) => {
      const response = await axios.post<InitializeTimelineResultModel | undefined>(
        `${mapServiceEndpoint}/timeline/initialize/${filterId}`,
        {
          txnId,
          product
        }
      );
      if (response.data) {
        return response.data;
      }
      return undefined;
    },
    {
      onSuccess: (data) => onSuccess && onSuccess(data)
    }
  );
  return {
    mutation
  };
}
export interface BubbleData {
  uwi: string;
  size: number;
  color: string;
  location: number[];
}
export interface TimelineIndexResponseModel {
  uwisInScene: { [uwi: string]: boolean };
  bubbleSize: BubbleData[];
}
let cancelTokenSource = undefined;
export function useTimeline(
  timelineId: string,
  index: number,
  maxValue: number,
  maxRadius: number
): ApiResult<TimelineIndexResponseModel> {
  const { data, isLoading, isError, refetch } = useQuery<TimelineIndexResponseModel>(
    "timeline",
    async () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
      // eslint-disable-next-line import/no-named-as-default-member
      cancelTokenSource = axios.CancelToken.source();
      if (!timelineId) {
        return undefined;
      }
      const response = await axios.get<TimelineIndexResponseModel>(
        `${mapServiceEndpoint}/timeline/scene/${timelineId}/${index}?maxValue=${maxValue}&maxRadius=${maxRadius}`
      );
      if (response.status === 200) {
        return response.data;
      }
      return undefined;
    },
    {
      enabled: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (axios.isCancel(error)) {
          return;
        }
      }
    }
  );
  return {
    data,
    isError,
    isLoading,
    refetch
  };
}
