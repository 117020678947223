import { useSelector } from "react-redux";

import { Switch } from "antd";
import classnames from "classnames";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { useChartDispatch, useChartState } from "components/chart/context";

import { visibilityToggleStyles } from "../shared/SharedStyles";

interface WellCountToggleT {
  type: string;
}

const WellCountToggle = ({ type = "Well" }: WellCountToggleT) => {
  // context and dispatch
  const dispatch = useChartDispatch();
  const { axisInputVisible, screenshot, settings } = useChartState();

  const widgetHoverMap = useSelector((state: RootState) => state.app.widgetHoverMap);
  const isHover = widgetHoverMap?.[settings.id] ?? false;

  // derived state
  const isTotalRateDate = settings.chartType === ALL_CHART_TYPES.TotalRateDate.label;
  const showWellCountToggle = isTotalRateDate && !screenshot.visible;

  const toggleLabel = type === "Well" ? "Well Count" : "Facility Count";

  const handleToggle = () => (value) => {
    const nextSettings = { ...settings, showWellCount: value };
    dispatch({ type: "settings", payload: nextSettings });
  };

  // derived classes
  const wrapperClasses = classnames({
    stackDown: axisInputVisible,
    visible: showWellCountToggle && isHover
  });

  return (
    <Wrapper
      className={wrapperClasses}
      data-testid="wellCountToggle"
      id="wellCountContainerId">
      <span>{toggleLabel}</span>
      <Switch
        size="small"
        checked={Boolean(settings.showWellCount)}
        onChange={handleToggle()}
      />
    </Wrapper>
  );
};

export default WellCountToggle;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  bottom: 4px;
  right: 36px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  z-index: 10;

  &.stackDown {
    z-index: 0;
  }
`;
