/* eslint-disable no-console */
import { useEffect, useRef } from "react";

import { useColumns } from "hooks";

import { getGeomBin } from "api/data";

import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import { useProjectLayerListQuery } from "components/project/layers/queries";
import { useProjectContext } from "components/project/projects/context";

import { useMapContext } from "../useMapContext";
import { geomBinTypes } from "./utils/constants";
import { onGeomBinsChanged } from "./utils/on-geom-bins-changed/onGeomBinsChanged";

export interface IuseManageMapboxGeomBinLoading {
  addLayer;
  removeLayer;
}
export default function useManageMapboxGeomBinLoading({
  addLayer
}: IuseManageMapboxGeomBinLoading) {
  // Context
  const { selectedProjectId, checkedTreeKeys } = useProjectContext();

  const { mapbox, isMapInitialized, isStyleLoaded } = useMapContext();

  const { isActive, selectedGeomBinId } = useGeomBinContext();

  const columns = useColumns();

  // Hooks
  const { data: layerList, refetch } = useProjectLayerListQuery({
    projectId: selectedProjectId
  });
  const checkedGeomBins = new Set(
    checkedTreeKeys?.filter((key) => layerList?.some((layer) => layer.geomBinId === key))
  );

  useEffect(() => {
    refetch();
  }, [columns, refetch]);
  // Refs
  /**
   * Used to compare to the last state of the geomBins to remove layers that are no longer checked
   * e.g Switching between projects, or checking geomBins
   */
  const lastCheckedGeomBins = useRef<Set<string>>();

  // Clear checkedGeomBins from mapbox when selectedProjectId changes
  useEffect(() => {
    const clearGeomBins = async () => {
      for (const geomBinId of checkedGeomBins) {
        let geomBinData;
        try {
          geomBinData = await getGeomBin(geomBinId);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error fetching geomBin:", error);
        }
        geomBinData?.nodes.forEach((geomBin) => {
          const sourceName = `${geomBin.binId}-${geomBin.order}`;
          try {
            for (const geomBinType of geomBinTypes) {
              const layerName = `${geomBin.binId}-${geomBin.order}${geomBinType}`;
              if (mapbox.getLayer(layerName)) mapbox.removeLayer(layerName);
            }
            if (mapbox.getSource(sourceName)) mapbox.removeSource(sourceName);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Unable to remove layer", err);
          }
        });
      }
    };

    if (selectedProjectId) {
      clearGeomBins();
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (!mapbox || !isStyleLoaded || !isMapInitialized) {
      return;
    }
    onGeomBinsChanged({
      addLayer,
      checkedGeomBins,
      lastCheckedGeomBins,
      layerList: layerList,
      mapbox,
      isActive,
      selectedGeomBinId
    });
  }, [
    mapbox,
    isStyleLoaded,
    isMapInitialized,
    checkedTreeKeys,
    layerList,
    selectedGeomBinId,
    isActive,
    columns
  ]);
}
