import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";
import { Subject } from "rxjs";

import ProjectWrapper from "../components/ProjectWrapper";
import {
  ProjectContextState,
  UpdateCheckedLayerKeys,
  UpdateCheckedTreeKeys,
  UpdateErrorState,
  UpdateIsAddingExistingFolders,
  UpdateIsHidingUnselectedLayers,
  UpdateIsUpdatingShapefile,
  UpdateIsUploadingShapefileList,
  UpdateLayerTreeExpandedKeys,
  UpdateLoadingState,
  UpdateManagementTree,
  UpdateProjectPermissions,
  UpdateProjectState,
  UpdateSelectedGroups,
  UpdateSelectedProjectId,
  UpdateSelectedView,
  UpdateViewOptions
} from "./ProjectContextState";

export const initialProjectContextState: ProjectContextState = {
  checkedLayerKeys: [],
  displaySettings: {
    displayMode: "list",
    sortDirection: "asc",
    hideUnselected: false
  },
  error: undefined,
  isAddingExistingFolders: true,
  isHidingUnselectedLayers: false,
  isLoading: false,
  isUpdatingShapefile: false,
  isUploadingShapefileList: false,
  layerTreeExpandedKeys: [],
  projectRefreshObserver: new Subject(),
  selectedProjectId: undefined,
  selectedViewId: undefined,
  managementTree: {
    selectedTreeNodes: null,
    uploadedRawShapefiles: []
  },
  viewOptions: "all",
  selectedGroups: []
};
export const ProjectContext = createContext(initialProjectContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const ProjectDispatchContext =
  createContext<React.Dispatch<Action<ProjectUpdates, "update">>>(undefined);

export type ProjectUpdates =
  | UpdateLoadingState
  | UpdateErrorState
  | UpdateProjectState
  | UpdateProjectPermissions
  | UpdateCheckedTreeKeys
  | UpdateCheckedLayerKeys
  | UpdateLayerTreeExpandedKeys
  | UpdateSelectedProjectId
  | UpdateManagementTree
  | UpdateIsUpdatingShapefile
  | UpdateIsUploadingShapefileList
  | UpdateIsAddingExistingFolders
  | UpdateViewOptions
  | UpdateIsHidingUnselectedLayers
  | UpdateSelectedView
  | UpdateSelectedGroups;
function ProjectReducer(
  state: ProjectContextState,
  action: Action<ProjectUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface ProjectProviderModel {
  children: ReactNode;
  state?: ProjectContextState;
  useWrapper?: boolean;
}

export function ProjectProvider({
  children,
  state: overrideState,
  useWrapper
}: ProjectProviderModel) {
  const [state, dispatch] = useReducer(
    ProjectReducer,
    overrideState ?? initialProjectContextState
  );

  if (useWrapper === false) {
    return (
      <ProjectContext.Provider value={state}>
        <ProjectDispatchContext.Provider value={dispatch}>
          {children}
        </ProjectDispatchContext.Provider>
      </ProjectContext.Provider>
    );
  }

  return (
    <ProjectContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>
        <ProjectWrapper>{children}</ProjectWrapper>
      </ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}
