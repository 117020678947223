import { IProductStyles } from "../models/shared.models";

export const tabs = Object.freeze({
  chartSettings: "Chart Settings",
  primary: "Primary",
  secondary: "Secondary"
});

export const productTabs = Object.freeze({
  Summary: "Summary",
  Wellhead: "Wellhead",
  Sales: "Sales",
  Injection: "Injection"
});

// Master list of colors
const colors = {
  gas: "#9c1717",
  oil: "#1c8017",
  water: "#163494",
  boe: "#ffa500",
  gor: "#2f4f4f",
  wgr: "#2e8b57",
  "total fluid": "#29AAB1",
  "oil cut": "#7fff00",
  "water cut": "#4169e1",
  isor: "#174c80",
  cgr: "#c961fa",
  csor: "#f08080",
  cwb: "#da70d6",
  iwb: "#d8bfd8",
  "gas inj": "#eee8aa",
  "steam inj": "#98fb98",
  "water inj": "#98fb98",
  wor: "#ff00ff",
  "1+wor": "#0000ff",
  c2: "#ff0000",
  c3: "#ff00ff",
  c4: "#0d2058",
  c5: "#9362c6",
  "sales gas": "#79efc0",
  "sales liquids": "#c0d67b",
  "gas energy": "#5c0aa4",
  "gas energy yields sales": "#9eb00f",
  shrinkage: "#20b749",
  "oil+cond+c5": "#a0a099",
  ngl_234: "#7706e7",
  "ngl mix": "#e06771",
  "sales boe": "#ff7f50",
  "oil+cond+c5 yields raw": "#33a936",
  "ngl_234 yields raw": "#ffc891",
  "ngl mix yields raw": "#65567c",
  "liquids yields raw": "#82b992",
  "c2 yields raw": "#f6793e",
  "c3 yields raw": "#cd6f31",
  "c4 yields raw": "#aee071",
  "c5 yields raw": "#eede9a",
  "oil+cond+c5 yields sales": "#4e40ba",
  "ngl_234 yields sales": "#c4ee85",
  "ngl mix yields sales": "#77c93d",
  "liquids yields sales": "#f340c7",
  "c2 yields sales": "#7078c4",
  "c3 yields sales": "#e2edfc",
  "c4 yields sales": "#df5acb",
  "c5 yields sales": "#d36c48",
  "csg pressure": "#5d58c9",
  "tbg pressure": "#d748bc",
  "on-time": "#777c24",
  "polymer inj": "#68ef1d",
  "total liquid inj": "#ab119f",
  "co_2 inj": "#c30004",
  "acid gas inj": "#ec5370",
  "solvent inj": "#32703e",
  "total gas inj": "#97a013"
};

// Use for future additions to forecast color if desired
// const lightenColors = (color: string) => lightenColorByPercentage(color, 25);
// Currently forecast/backfit keys do not mapping to the chart

export const productStyles: IProductStyles = {
  gas: {
    color: colors.gas,
    width: 2,
    dashed: false
  },
  "gas-fcst": {
    color: colors.gas,
    width: 2,
    dashed: true
  },
  "gas-backfit": {
    color: colors.gas,
    width: 2,
    dashed: false
  },
  oil: {
    color: colors.oil,
    width: 2,
    dashed: false
  },
  "oil-fcst": {
    color: colors.oil,
    width: 2,
    dashed: true
  },
  "oil-backfit": {
    color: colors.oil,
    width: 2,
    dashed: false
  },
  water: {
    color: colors.water,
    width: 2,
    dashed: false
  },
  "water-fcst": {
    color: colors.water,
    width: 2,
    dashed: true
  },
  "water-backfit": {
    color: colors.water,
    width: 2,
    dashed: false
  },
  boe: {
    color: colors.boe,
    width: 2,
    dashed: false
  },
  "boe-fcst": {
    color: colors.boe,
    width: 2,
    dashed: true
  },
  "boe-backfit": {
    color: colors.boe,
    width: 2,
    dashed: false
  },
  gor: {
    color: colors.gor,
    width: 2,
    dashed: false
  },
  "gor-fcst": {
    color: colors.gor,
    width: 2,
    dashed: true
  },
  "gor-backfit": {
    color: colors.gor,
    width: 2,
    dashed: false
  },
  wgr: {
    color: colors.wgr,
    width: 2,
    dashed: false
  },
  "wgr-fcst": {
    color: colors.wgr,
    width: 2,
    dashed: true
  },
  "wgr-backfit": {
    color: colors.wgr,
    width: 2,
    dashed: false
  },
  "total fluid": {
    color: colors["total fluid"],
    width: 2,
    dashed: false
  },
  "total fluid-fcst": {
    color: colors["total fluid"],
    width: 2,
    dashed: true
  },
  "total fluid-backfit": {
    color: colors["total fluid"],
    width: 2,
    dashed: false
  },
  "oil cut": {
    color: colors["oil cut"],
    width: 2,
    dashed: false
  },
  "oil cut-fcst": {
    color: colors["oil cut"],
    width: 2,
    dashed: true
  },
  "oil cut-backfit": {
    color: colors["oil cut"],
    width: 2,
    dashed: false
  },
  "water cut": {
    color: colors["water cut"],
    width: 2,
    dashed: false
  },
  "water cut-fcst": {
    color: colors["water cut"],
    width: 2,
    dashed: true
  },
  "water cut-backfit": {
    color: colors["water cut"],
    width: 2,
    dashed: false
  },
  isor: {
    color: colors.isor,
    width: 2,
    dashed: false
  },
  "isor-fcst": {
    color: colors.isor,
    width: 2,
    dashed: true
  },
  "isor-backfit": {
    color: colors.isor,
    width: 2,
    dashed: true
  },
  cgr: {
    color: colors.cgr,
    width: 2,
    dashed: false
  },
  "cgr-fcst": {
    color: colors.cgr,
    width: 2,
    dashed: true
  },
  "cgr-backfit": {
    color: colors.cgr,
    width: 2,
    dashed: true
  },
  csor: {
    color: colors.csor,
    width: 2,
    dashed: false
  },
  "csor-fcst": {
    color: colors.csor,
    width: 2,
    dashed: true
  },
  "csor-backfit": {
    color: colors.csor,
    width: 2,
    dashed: false
  },
  cwb: {
    color: colors.cwb,
    width: 2,
    dashed: false
  },
  "cwb-fcst": {
    color: colors.cwb,
    width: 2,
    dashed: true
  },
  "cwb-backfit": {
    color: colors.cwb,
    width: 2,
    dashed: false
  },
  iwb: {
    color: colors.iwb,
    width: 2,
    dashed: false
  },
  "iwb-fcst": {
    color: colors.iwb,
    width: 2,
    dashed: true
  },
  "iwb-backfit": {
    color: colors.iwb,
    width: 2,
    dashed: false
  },
  "gas inj": {
    color: colors["gas inj"],
    width: 2,
    dashed: false
  },
  "gas inj-fcst": {
    color: colors["gas inj"],
    width: 2,
    dashed: true
  },
  "gas inj-backfit": {
    color: colors["gas inj"],
    width: 2,
    dashed: false
  },
  "steam inj": {
    color: colors["steam inj"],
    width: 2,
    dashed: false
  },
  "steam inj-fcst": {
    color: colors["steam inj"],
    width: 2,
    dashed: true
  },
  "steam inj-backfit": {
    color: colors["steam inj"],
    width: 2,
    dashed: false
  },
  "water inj": {
    color: colors["water inj"],
    width: 2,
    dashed: false
  },
  "water inj-fcst": {
    color: colors["water inj"],
    width: 2,
    dashed: true
  },
  "water inj-backfit": {
    color: colors["water inj"],
    width: 2,
    dashed: false
  },
  wor: {
    color: colors.wor,
    width: 2,
    dashed: false
  },
  "wor-fcst": {
    color: colors.wor,
    width: 2,
    dashed: true
  },
  "wor-backfit": {
    color: colors.wor,
    width: 2,
    dashed: false
  },
  "1+wor": {
    color: colors["1+wor"],
    width: 2,
    dashed: false
  },
  "1+wor-fcst": {
    color: colors["1+wor"],
    width: 2,
    dashed: true
  },
  "1+wor-backfit": {
    color: colors["1+wor"],
    width: 2,
    dashed: false
  },
  c2: {
    color: colors["c2"],
    width: 2,
    dashed: false
  },
  "c2-fcst": {
    color: colors["c2"],
    width: 2,
    dashed: true
  },
  "c2-backfit": {
    color: colors["c2"],
    width: 2,
    dashed: false
  },
  c3: {
    color: colors["c3"],
    width: 2,
    dashed: false
  },
  "c3-fcst": {
    color: colors["c3"],
    width: 2,
    dashed: true
  },
  "c3-backfit": {
    color: colors["c2"],
    width: 2,
    dashed: false
  },
  c4: {
    color: colors["c4"],
    width: 2,
    dashed: false
  },
  "c4-fcst": {
    color: colors["c4"],
    width: 2,
    dashed: true
  },
  "c4-backfit": {
    color: colors["c4"],
    width: 2,
    dashed: false
  },
  c5: {
    color: colors["c5"],
    width: 2,
    dashed: false
  },
  "c5-fcst": {
    color: colors["c5"],
    width: 2,
    dashed: true
  },
  "c5-backfit": {
    color: colors["c2"],
    width: 2,
    dashed: false
  },
  "sales gas": {
    color: colors["sales gas"],
    width: 2,
    dashed: false
  },
  "sales gas-fcst": {
    color: colors["sales gas"],
    width: 2,
    dashed: true
  },
  "sales gas-backfit": {
    color: colors["sales gas"],
    width: 2,
    dashed: false
  },
  "gas energy": {
    color: colors["gas energy"],
    width: 2,
    dashed: false
  },
  "gas energy-fcst": {
    color: colors["gas energy"],
    width: 2,
    dashed: true
  },
  "gas energy-backfit": {
    color: colors["gas energy"],
    width: 2,
    dashed: false
  },
  "gas energy yields sales": {
    color: colors["gas energy yields sales"],
    width: 2,
    dashed: false
  },
  "gas energy yields sales-fcst": {
    color: colors["gas energy yields sales"],
    width: 2,
    dashed: true
  },
  "gas energy yields sales-backfit": {
    color: colors["gas energy yields sales"],
    width: 2,
    dashed: false
  },
  shrinkage: {
    color: colors["shrinkage"],
    width: 2,
    dashed: false
  },
  "shrinkage-fcst": {
    color: colors["shrinkage"],
    width: 2,
    dashed: true
  },
  "shrinkage-backfit": {
    color: colors["shrinkage"],
    width: 2,
    dashed: false
  },
  "sales liquids": {
    color: colors["sales liquids"],
    width: 2,
    dashed: false
  },
  "sales liquids-fcst": {
    color: colors["sales liquids"],
    width: 2,
    dashed: true
  },
  "sales liquids-backfit": {
    color: colors["sales liquids"],
    width: 2,
    dashed: false
  },
  "oil+cond+c5": {
    color: colors["oil+cond+c5"],
    width: 2,
    dashed: false
  },
  "oil+cond+c5-fcst": {
    color: colors["oil+cond+c5"],
    width: 2,
    dashed: true
  },
  "oil+cond+c5-backfit": {
    color: colors["oil+cond+c5"],
    width: 2,
    dashed: false
  },
  ngl_234: {
    color: colors["ngl_234"],
    width: 2,
    dashed: false
  },
  "ngl_234-fcst": {
    color: colors["ngl_234"],
    width: 2,
    dashed: true
  },
  "ngl_234-backfit": {
    color: colors["ngl_234"],
    width: 2,
    dashed: false
  },
  "ngl mix": {
    color: colors["ngl mix"],
    width: 2,
    dashed: false
  },
  "ngl mix-fcst": {
    color: colors["ngl mix"],
    width: 2,
    dashed: true
  },
  "ngl mix-backfit": {
    color: colors["ngl mix"],
    width: 2,
    dashed: false
  },
  "sales boe": {
    color: colors["sales boe"],
    width: 2,
    dashed: false
  },
  "sales boe-fcst": {
    color: colors["sales boe"],
    width: 2,
    dashed: true
  },
  "sales boe-backfit": {
    color: colors["sales boe"],
    width: 2,
    dashed: false
  },
  "oil+cond+c5 yields raw": {
    color: colors["oil+cond+c5 yields raw"],
    width: 2,
    dashed: false
  },
  "oil+cond+c5 yields raw-fcst": {
    color: colors["oil+cond+c5 yields raw"],
    width: 2,
    dashed: true
  },
  "oil+cond+c5 yields raw-backfit": {
    color: colors["oil+cond+c5 yields raw"],
    width: 2,
    dashed: false
  },
  "ngl_234 yields raw": {
    color: colors["ngl_234 yields raw"],
    width: 2,
    dashed: false
  },
  "ngl_234 yields raw-fcst": {
    color: colors["ngl_234 yields raw"],
    width: 2,
    dashed: true
  },
  "ngl_234 yields raw-backfit": {
    color: colors["ngl_234 yields raw"],
    width: 2,
    dashed: false
  },
  "ngl mix yields raw": {
    color: colors["ngl mix yields raw"],
    width: 2,
    dashed: false
  },
  "ngl mix yields raw-fcst": {
    color: colors["ngl mix yields raw"],
    width: 2,
    dashed: true
  },
  "ngl mix yields raw-backfit": {
    color: colors["ngl mix yields raw"],
    width: 2,
    dashed: false
  },
  "liquids yields raw": {
    color: colors["liquids yields raw"],
    width: 2,
    dashed: false
  },
  "liquids yields raw-fcst": {
    color: colors["liquids yields raw"],
    width: 2,
    dashed: true
  },
  "liquids yields raw-backfit": {
    color: colors["liquids yields raw"],
    width: 2,
    dashed: false
  },
  "c2 yields raw": {
    color: colors["c2 yields raw"],
    width: 2,
    dashed: false
  },
  "c2 yields raw-fcst": {
    color: colors["c2 yields raw"],
    width: 2,
    dashed: true
  },
  "c2 yields raw-backfit": {
    color: colors["c2 yields raw"],
    width: 2,
    dashed: false
  },
  "c3 yields raw": {
    color: colors["c3 yields raw"],
    width: 2,
    dashed: false
  },
  "c3 yields raw-fcst": {
    color: colors["c3 yields raw"],
    width: 2,
    dashed: true
  },
  "c3 yields raw-backfit": {
    color: colors["c3 yields raw"],
    width: 2,
    dashed: false
  },
  "c4 yields raw": {
    color: colors["c4 yields raw"],
    width: 2,
    dashed: false
  },
  "c4 yields raw-fcst": {
    color: colors["c4 yields raw"],
    width: 2,
    dashed: true
  },
  "c4 yields raw-backfit": {
    color: colors["c4 yields raw"],
    width: 2,
    dashed: false
  },
  "c5 yields raw": {
    color: colors["c5 yields raw"],
    width: 2,
    dashed: false
  },
  "c5 yields raw-fcst": {
    color: colors["c5 yields raw"],
    width: 2,
    dashed: true
  },
  "c5 yields raw-backfit": {
    color: colors["c5 yields raw"],
    width: 2,
    dashed: false
  },
  "oil+cond+c5 yields sales": {
    color: colors["oil+cond+c5 yields sales"],
    width: 2,
    dashed: false
  },
  "oil+cond+c5 yields sales-fcst": {
    color: colors["oil+cond+c5 yields sales"],
    width: 2,
    dashed: true
  },
  "oil+cond+c5 yields sales-backfit": {
    color: colors["oil+cond+c5 yields sales"],
    width: 2,
    dashed: false
  },
  "ngl_234 yields sales": {
    color: colors["ngl_234 yields sales"],
    width: 2,
    dashed: false
  },
  "ngl_234 yields sales-fcst": {
    color: colors["ngl_234 yields sales"],
    width: 2,
    dashed: true
  },
  "ngl_234 yields sales-backfit": {
    color: colors["ngl_234 yields sales"],
    width: 2,
    dashed: false
  },
  "ngl mix yields sales": {
    color: colors["ngl mix yields sales"],
    width: 2,
    dashed: false
  },
  "ngl mix yields sales-fcst": {
    color: colors["ngl mix yields sales"],
    width: 2,
    dashed: true
  },
  "ngl mix yields sales-backfit": {
    color: colors["ngl mix yields sales"],
    width: 2,
    dashed: false
  },
  "liquids yields sales": {
    color: colors["liquids yields sales"],
    width: 2,
    dashed: false
  },
  "liquids yields sales-fcst": {
    color: colors["liquids yields sales"],
    width: 2,
    dashed: true
  },
  "liquids yields sales-backfit": {
    color: colors["liquids yields sales"],
    width: 2,
    dashed: false
  },
  "c2 yields sales": {
    color: colors["c2 yields sales"],
    width: 2,
    dashed: false
  },
  "c2 yields sales-fcst": {
    color: colors["c2 yields sales"],
    width: 2,
    dashed: true
  },
  "c2 yields sales-backfit": {
    color: colors["c2 yields sales"],
    width: 2,
    dashed: false
  },
  "c3 yields sales": {
    color: colors["c3 yields sales"],
    width: 2,
    dashed: false
  },
  "c3 yields sales-fcst": {
    color: colors["c3 yields sales"],
    width: 2,
    dashed: true
  },
  "c3 yields sales-backfit": {
    color: colors["c3 yields sales"],
    width: 2,
    dashed: false
  },
  "c4 yields sales": {
    color: colors["c4 yields sales"],
    width: 2,
    dashed: false
  },
  "c4 yields sales-fcst": {
    color: colors["c4 yields sales"],
    width: 2,
    dashed: true
  },
  "c4 yields sales-backfit": {
    color: colors["c4 yields sales"],
    width: 2,
    dashed: false
  },
  "c5 yields sales": {
    color: colors["c5 yields sales"],
    width: 2,
    dashed: false
  },
  "c5 yields sales-fcst": {
    color: colors["c5 yields sales"],
    width: 2,
    dashed: true
  },
  "c5 yields sales-backfit": {
    color: colors["c5 yields sales"],
    width: 2,
    dashed: false
  },
  "csg pressure": {
    color: colors["csg pressure"],
    width: 2,
    dashed: false
  },
  "csg pressure-fcst": {
    color: colors["csg pressure"],
    width: 2,
    dashed: true
  },
  "csg pressure-backfit": {
    color: colors["csg pressure"],
    width: 2,
    dashed: false
  },
  "tbg pressure": {
    color: colors["tbg pressure"],
    width: 2,
    dashed: false
  },
  "tbg pressure-fcst": {
    color: colors["tbg pressure"],
    width: 2,
    dashed: true
  },
  "tbg pressure-backfit": {
    color: colors["tbg pressure"],
    width: 2,
    dashed: false
  },
  "on-time": {
    color: colors["on-time"],
    width: 2,
    dashed: false
  },
  "on-time-fcst": {
    color: colors["on-time"],
    width: 2,
    dashed: true
  },
  "on-time-backfit": {
    color: colors["on-time"],
    width: 2,
    dashed: false
  },
  "polymer inj": {
    color: colors["polymer inj"],
    width: 2,
    dashed: false
  },
  "polymer inj-fcst": {
    color: colors["polymer inj"],
    width: 2,
    dashed: true
  },
  "polymer inj-backfit": {
    color: colors["polymer inj"],
    width: 2,
    dashed: false
  },
  "total liquid inj": {
    color: colors["total liquid inj"],
    width: 2,
    dashed: false
  },
  "total liquid inj-fcst": {
    color: colors["total liquid inj"],
    width: 2,
    dashed: true
  },
  "total liquid inj-backfit": {
    color: colors["total liquid inj"],
    width: 2,
    dashed: false
  },
  "co_2 inj": {
    color: colors["co_2 inj"],
    width: 2,
    dashed: false
  },
  "co_2 inj-fcst": {
    color: colors["co_2 inj"],
    width: 2,
    dashed: true
  },
  "co_2 inj-backfit": {
    color: colors["co_2 inj"],
    width: 2,
    dashed: false
  },
  "acid gas inj": {
    color: colors["acid gas inj"],
    width: 2,
    dashed: false
  },
  "acid gas inj-fcst": {
    color: colors["acid gas inj"],
    width: 2,
    dashed: true
  },
  "acid gas inj-backfit": {
    color: colors["acid gas inj"],
    width: 2,
    dashed: false
  },
  "solvent inj": {
    color: colors["solvent inj"],
    width: 2,
    dashed: false
  },
  "solvent inj-fcst": {
    color: colors["solvent inj"],
    width: 2,
    dashed: true
  },
  "solvent inj-backfit": {
    color: colors["ngl_234"],
    width: 2,
    dashed: false
  },
  "total gas inj": {
    color: colors["total gas inj"],
    width: 2,
    dashed: false
  },
  "total gas inj-fcst": {
    color: colors["total gas inj"],
    width: 2,
    dashed: true
  },
  "total gas inj-backfit": {
    color: colors["total gas inj"],
    width: 2,
    dashed: false
  }
};

export const McfToBcf = 0.000001;
export const bblMcfToMbblMmcf = 0.001;

export const UNFOCUSED_OPACITY = 0.5;
export const HOVER_LINE_WIDTH = 6;
