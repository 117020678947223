import { Tabs as AntDTabs } from "antd";
import { ChartTypeMultiProduct } from "constants/chart.constants";
import styled from "styled-components/macro";
import { getChartTypeSystemName } from "utils";

import InputCollectionHeader from "components/base/InputCollectionHeader";
import { chartTypeIconsGroupedMultiProduct } from "components/chart/ChartSelector";
import { tabs } from "components/multiphase-chart/constants/shared.constants";
import { useChartSettings } from "components/multiphase-chart/context";
import { setChartType, setCurrentTab } from "components/multiphase-chart/context/reducer";

import ProductTabs from "./ProductTabs/ProductTabs";
import XAxisSelector from "./Settings/XAxisSelector";
import "./Tabs.css";

const Tabs = (): JSX.Element => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const { currentPreset, isViewMode, currentTab } = chartSettings;

  const primaryCount = currentPreset.products?.filter(
    (p: { axis: string }) => p.axis === "primary"
  ).length;

  const secondaryCount = currentPreset.products?.filter(
    (p: { axis: string }) => p.axis === "secondary"
  ).length;

  const settingsTab = {
    key: tabs.chartSettings,
    label: tabs.chartSettings,
    children: (
      <>
        {ChartTypeMultiProduct.map((header) => (
          <InputCollectionHeader key={header.name} header={"Chart Type"}>
            {header.items.map((ct, tIdx) => (
              <ChartTypeButton
                key={ct}
                ellipses={false}
                onClick={() => {
                  if (!isViewMode) {
                    setChartType(chartSettingsDispatch, getChartTypeSystemName(ct));
                  }
                }}
                appearance=""
                className={`${
                  !isViewMode
                    ? getChartTypeSystemName(ct) === currentPreset?.chartType
                      ? "isSelected"
                      : "isAvailable"
                    : getChartTypeSystemName(ct) === currentPreset?.chartType
                    ? "isSelected"
                    : "readonlyButton"
                }`}>
                {chartTypeIconsGroupedMultiProduct[tIdx]}
                <span>{ct}</span>
              </ChartTypeButton>
            ))}
          </InputCollectionHeader>
        ))}
        <XAxisSelector />
      </>
    )
  };

  const primaryTab = {
    key: tabs.primary,
    label: `${tabs.primary} (${primaryCount}/2 )`,
    children: <ProductTabs isPrimary={true} />
  };

  const secondayTab = {
    key: tabs.secondary,
    label: `${tabs.secondary} (${secondaryCount}/2 )`,
    children: <ProductTabs isPrimary={false} />
  };

  return (
    <AntDTabs
      className="custom-ant-tabs-nav"
      activeKey={currentTab}
      items={[settingsTab, primaryTab, secondayTab]}
      onChange={(tab: string) => setCurrentTab(chartSettingsDispatch, tab)}
    />
  );
};

const ChartTypeButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  height: auto;
  border-radius: 4px;
  color: #041c2c;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  &.isSelected {
    cursor: default;
    background: rgba(var(--color-shadow-rgb), 0.3);
    color: #041c2c;
    svg {
      color: var(--color-primary);
    }
  }
  span {
    margin-top: 3px;
  }
  svg {
    color: #a2aaad;
    transition: color var(--duration-short);
  }
  &.isAvailable:hover {
    background: rgba(var(--color-shadow-rgb), 0.3);
    svg {
      color: var(--color-primary);
    }
  }

  &.readonlyButton {
    cursor: default;
  }

  caret-color: transparent;
`;

export default Tabs;
