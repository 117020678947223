import axios from "axios";

import { ShapefileT } from "models/model";

export const projectServiceEndpoint = process.env.REACT_APP_PROJECT_SERVICE;
export const geoMapServiceEndpoint = process.env.REACT_APP_GEO_MAP_SERVICE;

type ProjectType = "Personal" | "Organization" | "Shared" | "McDaniel";
export interface ProjectModel {
  projectId: string;
  projectName: string;
  public?: boolean;
  owner: string;
  projectType?: ProjectType;
  userList?: string[];
  groups?: string[];
}

export interface AddProjectModel {
  projectName: string;
  projectType: string;
  owner: string;
  userList?: string[];
  groups?: string[];
}

export interface ProjectPermissions {
  canCreate: boolean;
  // Referring to the project itself
  canEdit: boolean;
  canDelete: boolean;
  canView: boolean;
  canEditProjectItems: boolean;
  canEditShapefiles: boolean;
  canEditForecasts: boolean;
  canEditTypeWells: boolean;
  canEditFilters: boolean;
  canEditGroups: boolean;
}

export function getProjects() {
  return axios.get(`${projectServiceEndpoint}/projects`);
}

export function getProject(projectId: string) {
  return axios.get(`${projectServiceEndpoint}/project/${projectId}`);
}

export async function getProjectShapefiles(projectId) {
  return axios.get(`${projectServiceEndpoint}/shapefile-node/projectTree/${projectId}`);
}

export function GetShapefileBranch(projectId, onSuccess, onError) {
  axios
    .get(`${projectServiceEndpoint}/shapefile-node/tree/${projectId}`)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function GetOrgLevelShapefileBranch(onSuccess, onError) {
  axios
    .get(`${projectServiceEndpoint}/shapefile-node/orgTree`)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function GetOrgLevelShapefilesInProject(projectId, onSuccess, onError) {
  axios
    .get(`${projectServiceEndpoint}/shapefile-node/orgTree/${projectId}`)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function GetShapefileFeatures(
  shapefileId,
  onSuccess: (items: ShapefileT) => void,
  onError
) {
  axios
    .get<ShapefileT>(
      `${projectServiceEndpoint}/shapefile/${shapefileId}?includeGeom=true`
    )
    .then((response) => {
      onSuccess(response.data as ShapefileT);
    })
    .catch(onError);
}

export function DeleteShapefile(shapefileId, onSuccess, onError = null) {
  axios
    .delete(`${projectServiceEndpoint}/shapefile/${shapefileId}`)
    .then(onSuccess)
    .catch(onError);
}
export function DeleteShapefileNode(shapefileNodeId, onSuccess, onError = null) {
  axios
    .delete(`${projectServiceEndpoint}/shapefile-node/${shapefileNodeId}`)
    .then(onSuccess)
    .catch(onError);
}

export function NewShapefileNode(
  projectId,
  parentId,
  userId,
  order,
  onSuccess,
  onError = null
) {
  axios
    .post(
      `${projectServiceEndpoint}/shapefile-node/${parentId == null ? "" : parentId}`,
      {
        projectId,
        parentId,
        owner: userId.toString(),
        name: "New Folder",
        order
      }
    )
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function MoveShapefileNode(
  shapefileNodeId,
  moveToId,
  dropPosition,
  onSuccess,
  onError
) {
  axios
    .put(
      `${projectServiceEndpoint}/shapefile-node/move/${shapefileNodeId}${
        moveToId ? `/${moveToId}` : ""
      }`,
      {
        order: dropPosition
      }
    )
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function MoveShapefile(
  shapefileId,
  moveToId,
  dropPosition,
  projectId,
  onSuccess,
  onError
) {
  axios
    .put(
      `${projectServiceEndpoint}/shapefile/move/${shapefileId}${
        moveToId ? `/${moveToId}` : ""
      }`,
      {
        order: dropPosition,
        projectId: projectId
      }
    )
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

// TODO BF: remove after refactor
export function UpdateShapefileProperties(shapefileId, property, onSuccess, onError) {
  axios
    .put(`${projectServiceEndpoint}/shapefile/update/${shapefileId}`, property)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

// TODO BF: remove after refactor
export function UpdateShapefileColors(
  projectId,
  shapefileNodeId,
  property,
  onSuccess,
  onError
) {
  if (shapefileNodeId === "") {
    shapefileNodeId = "root";
  }
  axios
    .put(
      `${projectServiceEndpoint}/shapefile/colors/${projectId}/${shapefileNodeId}`,
      property
    )
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function SortShapefilesInFolder(
  projectId,
  shapefileNodeId,
  order,
  onSuccess,
  onError
) {
  if (shapefileNodeId === "") {
    shapefileNodeId = "root";
  }
  axios
    .put(
      `${projectServiceEndpoint}/shapefile/sort/${projectId}/${shapefileNodeId}/${order}`
    )
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function UpdateShapefileNodeProperties(
  shapefileNodeId,
  property,
  onSuccess,
  onError
) {
  axios
    .put(`${projectServiceEndpoint}/shapefile-node/update/${shapefileNodeId}`, property)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}

export function UpdateShapefileFeatureProperties(
  shapefileGeomId,
  node,
  onSuccess,
  onError
) {
  axios
    .put(`${projectServiceEndpoint}/shapefile-geom/update/${shapefileGeomId}`, node)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(onError);
}
