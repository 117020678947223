import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useQuery } from "@apollo/client";
import { Select } from "antd";
import {
  MCDANIEL_SEARCH_FOLDER_NAME,
  NOVI_SEARCH_FOLDER_NAME,
  USER_ARPS,
  WORKING_FORECAST_FOLDER_NAME,
  WORKING_FORECAST_SOURCE
} from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { FOLDERS } from "api/userArps";

import { useSelectedProject } from "components/project/projects/hooks";

import { numberToRescatString } from "../sync/util";
import { useGroupByUpdater } from "./group-by.context";

interface ProjectForecast {
  name: string;
  folderId: string;
  rescat: string[];
}

interface ForecastSourceSelectModel {
  showLabel?: boolean;
  showGroup?: boolean;
}

export default function ForecastSourceSelect({
  showGroup = false,
  showLabel = true
}: ForecastSourceSelectModel) {
  const stateDispatch = useGroupByUpdater();
  const { selectedProject: project } = useSelectedProject();
  const userSync = useSelector((state: RootState) => state.app.syncWells);
  const [forecastList, setForecastList] = useState<ProjectForecast[]>([]);
  const [selectedForecastFolder, setSelectedForecastFolder] = useState("");
  const [selectedForecastFolderName, setSelectedForecastFolderName] = useState("");
  const [selectedRescat, setSelectedRescat] = useState("");
  const [forecastRescats, setForecastRescats] = useState([]);

  const { data: foldersData } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId ?? "",
        type: USER_ARPS
      }
    },
    skip: !project?.projectId
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  useEffect(() => {
    if (!foldersData) {
      return;
    }

    const list = [
      {
        name: MCDANIEL_SEARCH_FOLDER_NAME,
        folderId: MCDANIEL_SEARCH_FOLDER_NAME,
        rescat: ["P+PDP"]
      },
      {
        name: NOVI_SEARCH_FOLDER_NAME,
        folderId: "shaleProfileDB",
        rescat: ["P+PDP"]
      },
      {
        name: WORKING_FORECAST_FOLDER_NAME,
        folderId: WORKING_FORECAST_SOURCE,
        rescat: ["P+PDP"]
      }
    ].concat(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      foldersData.folders.map((fc: any) => {
        return {
          name: fc.name,
          folderId: fc.folderId,
          rescat: fc.forecasts.map((f) => f.reserveCategory).filter(onlyUnique)
        } as ProjectForecast;
      })
    );
    //don't need to check id because list size is always > 0;
    let selectedForecastFolderId = list[0].folderId;
    let selectedForecastFolderName = list[0].name;
    if (userSync?.schema) {
      list.push({
        name: userSync.schema,
        folderId: userSync.schema,
        rescat: [numberToRescatString(userSync.forecastRescat)]
      });
      if (userSync?.isSync && userSync.useFcstFromClient) {
        selectedForecastFolderId = userSync.schema;
        selectedForecastFolderName = userSync.schema;
      }
    }
    setForecastList(list);
    setSelectedForecastFolder(selectedForecastFolderId);
    setSelectedForecastFolderName(selectedForecastFolderName);
  }, [foldersData, userSync]);

  useEffect(() => {
    if (!selectedForecastFolder) {
      return;
    }
    const forecast = forecastList.find((fc) => fc.folderId === selectedForecastFolder);
    if (!forecast) {
      return;
    }
    setForecastRescats(
      forecast.rescat.map((rescat) => ({ label: rescat, value: rescat }))
    );
    if (forecast.rescat) {
      setSelectedRescat(forecast.rescat[0]);
    }
    stateDispatch({
      type: "selected_forecast_folder",
      payload: selectedForecastFolder
    });
    stateDispatch({
      type: "selected_forecast_folder_name",
      payload: selectedForecastFolderName
    });
  }, [selectedForecastFolder, forecastList, stateDispatch]);

  useEffect(() => {
    if (!selectedForecastFolder || !selectedRescat) {
      return;
    }
    stateDispatch({
      type: "selected_rescat",
      payload: selectedRescat
    });
  }, [selectedForecastFolder, selectedRescat, stateDispatch]);

  return (
    <div>
      {showLabel && <label>Forecast Source</label>}
      <Wrapper>
        <Select
          popupClassName="modal-select"
          value={selectedForecastFolder}
          onChange={(value, label) => {
            setSelectedForecastFolder(value);
            setSelectedForecastFolderName(Object.values(label)[1]);
          }}
          options={
            showGroup
              ? ([
                  {
                    label: "Forecast Source",
                    options: forecastList.map((fc) => ({
                      value: fc.folderId,
                      label: fc.name
                    }))
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ] as any)
              : forecastList.map((fc) => ({
                  value: fc.folderId,
                  label: fc.name
                }))
          }
        />
        <RescatWrapper>
          <Select
            value={selectedRescat}
            options={[{ label: "Rescat", options: forecastRescats }]}
            onChange={setSelectedRescat}
            popupClassName="modal-select"
          />
        </RescatWrapper>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-content: center;
  gap: var(--space-2);
`;

const RescatWrapper = styled.div`
  max-width: 95px;
  width: 95px;

  > div {
    width: 100%;
  }
`;
