import { useEffect, useMemo } from "react";

import { Tabs as AntDTabs } from "antd";
import {
  ChartProduct,
  InjectionProduct,
  SalesProduct,
  SummaryProduct
} from "constants/chart.constants";

import { productTabs } from "components/multiphase-chart/constants/shared.constants";
import { useChartSettings } from "components/multiphase-chart/context";
import { setCurrentProductTab } from "components/multiphase-chart/context/reducer";

import Products from "../Products/Products";

export interface IProductTabs {
  isPrimary: boolean;
}

const ProductTabs = ({ isPrimary }: IProductTabs) => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const { currentTab, currentProductTab, currentPreset } = chartSettings;

  const createTab = (key: string) => ({
    key,
    label: key,
    children: <Products tab={key} isPrimary={isPrimary} />
  });

  const tabs = useMemo(
    () => [
      createTab(productTabs.Summary),
      createTab(productTabs.Wellhead),
      createTab(productTabs.Sales),
      createTab(productTabs.Injection)
    ],
    [isPrimary]
  );

  useEffect(() => {
    if (currentPreset?.products?.length) {
      const firstProduct = currentPreset.products.find(
        (p) => p.axis === (currentTab === "Primary" ? "primary" : "secondary")
      );

      if (SummaryProduct.some((p) => p.items.includes(firstProduct?.name))) {
        setCurrentProductTab(chartSettingsDispatch, productTabs.Summary);
      } else if (ChartProduct.some((p) => p.items.includes(firstProduct?.name))) {
        setCurrentProductTab(chartSettingsDispatch, productTabs.Wellhead);
      } else if (SalesProduct.some((p) => p.items.includes(firstProduct?.name))) {
        setCurrentProductTab(chartSettingsDispatch, productTabs.Sales);
      } else if (InjectionProduct.some((p) => p.items.includes(firstProduct?.name))) {
        setCurrentProductTab(chartSettingsDispatch, productTabs.Injection);
      }
    } else {
      // default to summary tab
      setCurrentProductTab(chartSettingsDispatch, productTabs.Summary);
    }
  }, [currentTab]);

  return (
    <AntDTabs
      activeKey={currentProductTab}
      items={tabs}
      onChange={(tab: string) => setCurrentProductTab(chartSettingsDispatch, tab)}
    />
  );
};

export default ProductTabs;
