import { Fragment, memo } from "react";

import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";

import CategoryListItem from "./CategoryListItem";
import { useCategories } from "./hooks";

function GroupCategoryList() {
  const categoryList = useCategories();
  const { hasFeature } = useBetaFeatures();

  // do not render if category-list is not initialized
  if (!categoryList?.length) return null;

  const MemoizedItem = memo(CategoryListItem);

  let currentSection = "Primary";

  return (
    <Wrapper>
      {categoryList.map((item) => {
        let useDivider = false;
        if (currentSection !== item.section) {
          useDivider = true;
          currentSection = item.section;
        }
        if (hasFeature("Facility Volumes") && item.name === "Midstream") {
          return null;
        }

        return (
          <Fragment key={item.name}>
            {useDivider && <Divider />}
            <MemoizedItem value={item} />
          </Fragment>
        );
      })}
    </Wrapper>
  );
}

export default memo(GroupCategoryList);

const Wrapper = styled.div`
  min-width: 184px;
  display: grid;
  align-content: start;
  background-color: var(--color-white);
  padding: var(--space-2) 0;
  overflow: hidden overlay;
`;

const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 10px;
  margin-bottom: 10px;
`;
