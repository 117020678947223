export interface IScaleBy {
  value: string;
  unit: string;
  field: string;
  defaultValue: number;
}

export const scaleByOptions: IScaleBy[] = [
  {
    value: "Proppant Total per entry point",
    unit: "t",
    field: "TotalProppantPlaced",
    defaultValue: 250
  },
  {
    value: "Fluid Total per entry point",
    unit: "m3",
    field: "TotalFluid",
    defaultValue: 1250
  },
  {
    value: "Average Rate per entry point",
    unit: "m3/min",
    field: "AverageRate",
    defaultValue: 15
  },
  {
    value: "Break Down Pressure",
    unit: "mpa",
    field: "BreakdownPressure",
    defaultValue: 75
  },
  {
    value: "Max Prop Concentration",
    unit: "kg/m3",
    field: "MaxPropConcentration",
    defaultValue: 1500
  },
  {
    value: "Proppant Total per stage",
    unit: "t",
    field: "TotalProppantStage",
    defaultValue: 1250
  },
  {
    value: "Proppant Total per stage m",
    unit: "t",
    field: "TotalProppantM",
    defaultValue: 25
  }
];

// constants for radius dimensions
export const RADIUS_DIMENSION_MIN = 0;
export const RADIUS_DIMENSION_MAX = 1000;
export const INITIAL_SCALE_BY_VALUE = 1000;
