import {
  FORECAST_KEY,
  MCDANIEL_SEARCH_FOLDER_NAME,
  SYNC_KEY
} from "constants/settings.constants";

import { isSyncFolder } from "components/sync/util";

const getPropertiesWithForecast = (properties, dataSources) => {
  if (dataSources.forecastSource !== MCDANIEL_SEARCH_FOLDER_NAME) {
    return properties.map((prop) => {
      if (prop.startsWith("Forecast.") || prop.startsWith("Performance.")) {
        return (
          prop + `.${FORECAST_KEY}.${dataSources.forecastSource}.${dataSources.rescat}`
        );
      }
      if (prop.endsWith("EUR") || prop.endsWith("Remaining")) {
        return prop + `.fcst.${dataSources.forecastSource}.${dataSources.rescat}`;
      }
      if (isSyncFolder(dataSources.forecastSource) && prop.startsWith("Performance")) {
        //add .sync prefix to sync folder
        return prop + `.${SYNC_KEY}.${dataSources.forecastSource}`;
      }
      return prop;
    });
  } else {
    return properties;
  }
};

export default getPropertiesWithForecast;
