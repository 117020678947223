import { useSelector } from "react-redux";

import { DEFAULT_CHART_STATE, getMidstreamChartTypes } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";

import { AllChartCapabilities, ChartWrapper } from "components/chart";
import { ChartProvider } from "components/chart/context";
import ScreenshotActivity from "components/screenshot/ScreenshotActivity";
import { useScreenshotContext } from "components/screenshot/hooks";
import { useWellsFromFilter } from "components/well-list/hooks";

import { MidstreamProductGroups } from "../../models/chart";
import { EntityKind } from "../../models/entityKind";

function SingleComponentChart() {
  // state from store
  const channelId = useSelector((state: RootState) => state.channel.channel);
  const { hasFeature } = useBetaFeatures();

  // context from hooks
  const { widget } = useScreenshotContext();

  // session storage data
  const channelData = sessionStorage.getItem(channelId);

  // derived state
  const initialState = channelData ? JSON.parse(channelData) : null;
  const initialSettings = initialState ? initialState.settings : null;
  const entityKind = initialState?.entityKind ?? EntityKind.Well;
  const availableChartTypes =
    entityKind == EntityKind.Well
      ? []
      : getMidstreamChartTypes(hasFeature("Facility Volumes"));

  useWellsFromFilter();
  return (
    <Wrapper>
      {widget ? (
        <ActivityWrapper>
          <ScreenshotActivity />
        </ActivityWrapper>
      ) : (
        <div></div>
      )}
      <ChartProvider
        initialState={{
          ...DEFAULT_CHART_STATE,
          settings: initialSettings,
          entityKind,
          availableChartTypes,
          availableChartProductGroups:
            entityKind == EntityKind.Well
              ? ["Sales", "Summary", "Wellhead", "Injection"]
              : MidstreamProductGroups,
          chartCapabilities:
            entityKind == EntityKind.Well
              ? [
                  AllChartCapabilities.DataSourceSelection,
                  AllChartCapabilities.Forecast,
                  AllChartCapabilities.CD_PD,
                  AllChartCapabilities.TypeWell,
                  AllChartCapabilities.TimeStep,
                  AllChartCapabilities.Normalize
                ]
              : []
        }}>
        <StyledChartWrapper id="custom" />
      </ChartProvider>
    </Wrapper>
  );
}

export default SingleComponentChart;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  width: 100%;
`;

const ActivityWrapper = styled.div`
  border-right: 1px solid rgba(var(--color-text-rgb), 0.1);
  width: 359px;
`;

const StyledChartWrapper = styled(ChartWrapper)`
  .ChartPlot.screenshot {
    position: relative;
    top: 60px;
    min-height: auto;
    min-width: auto;
    max-width: none;
    max-height: none;
  }
`;
