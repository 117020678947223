import { getMapLayersSessionKey } from "./getMapLayersSessionKey";

export function setMapLayersToSession({
  mapLayers,
  projectId
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapLayers: any;
  projectId: string;
}) {
  const sessionKey = getMapLayersSessionKey(projectId);
  sessionStorage.setItem(sessionKey, JSON.stringify(mapLayers));
}
