import { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

import { EChartsType } from "hd-echarts/echarts";

import { IChartScreenshotSettings, IChartSettings, lineCoordinates } from "models/chart";
import { IChartResult } from "models/model";

import { ChartTypeT } from "../../../constants";
import { EntityKind } from "../../../models/entityKind";

type Point = { x: number; y: number };
export type ScreenshotT = {
  visible: boolean;
  preset: IChartScreenshotSettings | null;
};

export type Action =
  | { type: "axisInputVisible"; payload: boolean }
  | { type: "bounds"; payload: DOMRect | null }
  | { type: "brushEnd"; payload: boolean }
  | { type: "brushEndPosition"; payload: Point | null }
  | { type: "brushedList"; payload: Array<string> | null }
  | { type: "busy"; payload: boolean }
  | { type: "contextMenuPosition"; payload: Point | null }
  | { type: "fullscreen"; payload: boolean }
  | { type: "id"; payload: string }
  | { type: "isJitterPlotXBinDefaulting"; payload: boolean }
  | { type: "isProbitDataFromLineOfBestFit"; payload: boolean }
  | { type: "instance"; payload: EChartsType }
  | { type: "lasso"; payload: boolean }
  | { type: "overflow"; payload: number }
  | { type: "options"; payload }
  | { type: "response"; payload: IChartResult }
  | { type: "request"; payload }
  | { type: "screenshot"; payload: ScreenshotT }
  | { type: "settings"; payload: IChartSettings }
  | { type: "unitSlopePosition"; payload: lineCoordinates }
  | { type: "halfSlopePosition"; payload: lineCoordinates }
  | { type: "quarterSlopePosition"; payload: lineCoordinates };

export type Dispatch = (action: Action) => void;

export type ChartProductGroupT =
  | "Summary"
  | "Wellhead"
  | "Sales"
  | "Throughput"
  | "Shrinkage"
  | "Injection";

export type ChartCapabilitiesT =
  | "DataSourceSelection"
  | "Forecast"
  | "CD_PD"
  | "TimeStep"
  | "TypeWell"
  | "Normalize";

export const AllChartCapabilities: {
  [name in ChartCapabilitiesT]: { name: ChartCapabilitiesT; key: string };
} = {
  DataSourceSelection: { name: "DataSourceSelection", key: "source" },
  Forecast: { name: "Forecast", key: "forecast" },
  CD_PD: { name: "CD_PD", key: "producing" },
  TypeWell: { name: "TypeWell", key: "typewells" },
  TimeStep: { name: "TimeStep", key: "timestep" },
  Normalize: { name: "Normalize", key: "normalize" }
};

export const availableChartCapabilities = Object.values(AllChartCapabilities).map(
  (capability) => capability.key
);

export type StateT = {
  // TODO: temporary fix. lift state and extract all absolute positioned components to root
  axisInputVisible: boolean;
  bounds: DOMRect | null;
  brushEnd: boolean;
  brushEndPosition: Point | null;
  brushedList: Array<string> | null;
  busy: boolean;
  contextMenuPosition: Point | null;
  id: string;
  instance: EChartsType;
  isFullscreen: boolean;
  isJitterPlotXBinDefaulting?: boolean;
  isProbitDataFromLineOfBestFit?: boolean;
  lasso: boolean;
  overflowIndex: number;
  options;
  response: IChartResult;
  request;
  screenshot: ScreenshotT;
  settings: IChartSettings | null;
  chartCapabilities: { name: ChartCapabilitiesT; key: string }[];
  availableChartProductGroups: ChartProductGroupT[];
  availableChartTypes: ChartTypeT[];
  entityKind: EntityKind;
  unitSlopePosition: lineCoordinates;
  halfSlopePosition: lineCoordinates;
  quarterSlopePosition: lineCoordinates;
};

type ChartProviderT = {
  children: ReactNode;
  initialState: StateT;
};

const ChartStateContext = createContext<StateT | undefined>(undefined);
const ChartDispatchContext = createContext<Dispatch | undefined>(undefined);

const defaultState = {
  axisInputVisible: false,
  bounds: null,
  brushEnd: false,
  brushEndPosition: null,
  brushedList: null,
  busy: false,
  contextMenuPosition: null,
  id: "",
  instance: null,
  isFullscreen: false,
  isJitterPlotXBinDefaulting: false,
  isProbitDataFromLineOfBestFit: false,
  lasso: false,
  overflowIndex: 0,
  options: null,
  response: null,
  request: null,
  screenshot: {
    visible: false,
    preset: null
  },
  settings: null,
  chartCapabilities: [],
  availableChartProductGroups: [],
  availableChartTypes: [],
  entityKind: EntityKind.Well,
  unitSlopePosition: null,
  halfSlopePosition: null,
  quarterSlopePosition: null
} as StateT;

// TODO: replace with `immer` curried producer
const actionMap = {
  axisInputVisible: (s, a) => ({ ...s, axisInputVisible: a.payload }),
  bounds: (s, a) => ({ ...s, bounds: a.payload }),
  brushEnd: (s, a) => ({ ...s, brushEnd: a.payload }),
  brushEndPosition: (s, a) => ({ ...s, brushEndPosition: a.payload }),
  brushedList: (s, a) => ({ ...s, brushedList: a.payload }),
  busy: (s, a) => ({ ...s, busy: a.payload }),
  contextMenuPosition: (s, a) => ({ ...s, contextMenuPosition: a.payload }),
  fullscreen: (s, a) => ({ ...s, isFullscreen: a.payload }),
  id: (s, a) => ({ ...s, id: a.payload }),
  instance: (s, a) => ({ ...s, instance: a.payload }),
  isJitterPlotXBinDefaulting: (s, a) => ({ ...s, isJitterPlotXBinDefaulting: a.payload }),
  isProbitDataFromLineOfBestFit: (s, a) => ({
    ...s,
    isProbitDataFromLineOfBestFit: a.payload
  }),
  lasso: (s, a) => ({ ...s, lasso: a.payload }),
  legend: (s, a) => ({ ...s, legend: a.payload }),
  overflow: (s, a) => ({ ...s, overflowIndex: a.payload }),
  options: (s, a) => ({ ...s, options: a.payload }),
  response: (s, a) => ({ ...s, response: a.payload }),
  request: (s, a) => ({ ...s, request: a.payload }),
  screenshot: (s, a) => ({ ...s, screenshot: a.payload }),
  settings: (s, a) => ({ ...s, settings: a.payload }),
  unitSlopePosition: (s, a) => ({ ...s, unitSlopePosition: a.payload }),
  halfSlopePosition: (s, a) => ({ ...s, halfSlopePosition: a.payload }),
  quarterSlopePosition: (s, a) => ({ ...s, quarterSlopePosition: a.payload })
};

const chartReducer = (state: StateT, action: Action) => {
  if (!Object.prototype.hasOwnProperty.call(actionMap, action.type)) {
    throw new Error(`Invalid action type: ${action.type}`);
  }

  const handler = actionMap[action.type];
  return handler(state, action);
};

function ChartProvider({ children, initialState }: ChartProviderT) {
  const newState = initialState ? initialState : defaultState;
  const [state, dispatch] = useReducer(chartReducer, newState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ChartStateContext.Provider value={value.state}>
      <ChartDispatchContext.Provider value={value.dispatch}>
        {children}
      </ChartDispatchContext.Provider>
    </ChartStateContext.Provider>
  );
}

function useChartState(): StateT {
  const context = useContext(ChartStateContext);
  if (context === undefined) {
    throw new Error("useChartState must be used within a ChartProvider");
  }
  return context;
}

function useChartDispatch() {
  const context = useContext(ChartDispatchContext);
  if (context === undefined) {
    throw new Error("useChartUpdater must be used within a ChartProvider");
  }
  return context;
}

// context module functions
const toggleAxisInput = (dispatch: Dispatch, value: boolean) =>
  dispatch({ type: "axisInputVisible", payload: value });

/*const toggleLasso = (dispatch: Dispatch, value: boolean) =>
  dispatch({ type: "lasso", payload: value });*/
const toggleLassoON = (dispatch: Dispatch) => dispatch({ type: "lasso", payload: true });
const toggleLassoOFF = (dispatch: Dispatch) =>
  dispatch({ type: "lasso", payload: false });

const updateBrushEnd = (dispatch: Dispatch, value: boolean) =>
  dispatch({ type: "brushEnd", payload: value });
const updateBrushEndPosition = (dispatch: Dispatch, value: Point | null) =>
  dispatch({ type: "brushEndPosition", payload: value });
const updateBrushedList = (dispatch: Dispatch, value: Array<string>) =>
  dispatch({ type: "brushedList", payload: value });

/*const updateChartLegend = (dispatch: Dispatch, value: LegendT) =>
  dispatch({ type: "legend", payload: value });*/
const updateChartResponse = (dispatch: Dispatch, value: IChartResult) =>
  dispatch({ type: "response", payload: value });
const updateChartRequest = (dispatch: Dispatch, value) =>
  dispatch({ type: "request", payload: value });
const updateChartOptions = (dispatch: Dispatch, value) =>
  dispatch({ type: "options", payload: value });
const updateContextMenuPosition = (dispatch: Dispatch, value: Point) =>
  dispatch({ type: "contextMenuPosition", payload: value });
const updateIsJitterPlotXBinDefaulting = (dispatch: Dispatch, value: boolean) =>
  dispatch({ type: "isJitterPlotXBinDefaulting", payload: value });
const updateIsProbitDataFromLineOfBestFit = (dispatch: Dispatch, value: boolean) =>
  dispatch({ type: "isProbitDataFromLineOfBestFit", payload: value });
// Example usage of the new action
const updateUnitSlopePosition = (dispatch: Dispatch, value: lineCoordinates) =>
  dispatch({ type: "unitSlopePosition", payload: value });
const updateHalfSlopePosition = (dispatch: Dispatch, value: lineCoordinates) =>
  dispatch({ type: "halfSlopePosition", payload: value });
const updateQuarterSlopePosition = (dispatch: Dispatch, value: lineCoordinates) =>
  dispatch({ type: "quarterSlopePosition", payload: value });

/*const updateChartScreenshot = (dispatch: Dispatch, value: ScreenshotT) =>
  dispatch({ type: "screenshot", payload: value });
const updateChartSettings = (dispatch: Dispatch, value: IChartSettings) =>
  dispatch({ type: "settings", payload: value });*/

export {
  ChartProvider,
  defaultState as defaultChartState,
  useChartState,
  useChartDispatch,
  toggleAxisInput,
  //toggleLasso,
  toggleLassoON,
  toggleLassoOFF,
  updateBrushEnd,
  updateBrushEndPosition,
  updateBrushedList,
  updateChartResponse,
  updateChartRequest,
  updateChartOptions,
  //updateChartScreenshot,
  //updateChartSettings,
  updateContextMenuPosition,
  updateIsJitterPlotXBinDefaulting,
  updateIsProbitDataFromLineOfBestFit,
  updateUnitSlopePosition,
  updateHalfSlopePosition,
  updateQuarterSlopePosition
  //updateChartLegend
};

export type { ChartProviderT };
