import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import { getObjectPropertyValueByKey } from "utils/objects";

import { IProductType } from "models/chart";

import { PerformanceTypeT } from "components/multiphase-chart/components/settings/toggles/presets/Tabs/Products/ProductStyleSelector";
import { productStyles } from "components/multiphase-chart/constants/shared.constants";
import { ISeriesStyle } from "components/multiphase-chart/models/shared.models";

import getPerformanceTypeKey from "../getPerformanceTypeKey/getPerformanceTypeKey";

const getDefaultSeriesStyleByProductLabel = (
  label: string,
  performanceType?: PerformanceTypeT
): ISeriesStyle => {
  //TODO: Move out
  function getProductTypeWithoutPostFix(): IProductType {
    const labelLowerCase = label?.toLowerCase();
    const performanceTypeLowerCase = performanceType?.toLowerCase();
    const indexOfPerformanceType: number = labelLowerCase?.indexOf(
      performanceTypeLowerCase
    );

    const newLabel: string = (
      indexOfPerformanceType !== -1
        ? label?.substring(0, indexOfPerformanceType).trim()
        : label
    )?.toLowerCase();

    const productType = Object.values(ALL_PRODUCT_TYPES).find((type: IProductType) => {
      return (
        type?.key?.toLowerCase() === newLabel || type.label?.toLowerCase() === newLabel
      );
    });

    return productType;
  }
  const product = getProductTypeWithoutPostFix()?.key;

  const productKeyBase = product?.toLocaleLowerCase();
  const productKey = `${productKeyBase}${getPerformanceTypeKey(performanceType)}`;
  const productStyle = getObjectPropertyValueByKey(productStyles, productKey);

  return productStyle
    ? {
        product: productKey,
        color: productStyle.color,
        dashed: productStyle.dashed,
        width: productStyle.width
      }
    : // Maybe overkill, but mitigates crash risk if a new product can't be found, should be obvious with large width
      {
        product: productKey,
        color: "#000000",
        width: 10,
        dashed: !!performanceType
      };
};

export default getDefaultSeriesStyleByProductLabel;
