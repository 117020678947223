// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiCheck,
  mdiClose,
  mdiContentSave,
  mdiContentSavePlus,
  mdiDelete,
  mdiDomain,
  mdiDotsVertical,
  mdiFormTextbox
} from "@mdi/js";
import { Button, Input, Popconfirm, Popover, Select } from "antd";
import { ForecastActivityType } from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { SaveAsToggle } from "components/arps/SaveAsToggle";
import { TypeWellAction } from "components/arps/TypeWellAction";
import { Tooltip } from "components/base";
import ExportForecastsButton, {
  WellSource
} from "components/project/forecasts/ExportForecastsButton";
import {
  useSelectedProject,
  useSelectedProjectPermissions
} from "components/project/projects/hooks";
import { useProjectListQuery } from "components/project/projects/queries";

import { useUser } from "../../../../hooks";
import { MoveTypeWellInput, ProjectWithPermission } from "../../../../models/projects";
import { setIsTypeWellSavable } from "../../../../store/features";
import { useTypeWellResetter, useTypeWellUpdater } from "../../hooks";
import { useDeleteForecast } from "../../hooks/useDeleteForecast";
import { IUndoRedoManager } from "../../hooks/useUndoRedo";

const { Option } = Select;

interface ITypeWellEditorHeader {
  handleSave;
  handleClone: (
    newTypeWellTitle: string,
    moveTypeWellToProjectInput?: MoveTypeWellInput
  ) => void;
  isEditTypeWellPanelOpen: boolean;
  isWidget: boolean;
  saveStatus;
  type: ForecastActivityType;
  undoRedoManager: IUndoRedoManager;
  hideButtons?: boolean;
}

export function TypeWellEditorHeader({
  handleSave,
  handleClone,
  isEditTypeWellPanelOpen,
  isWidget,
  saveStatus,
  type,
  undoRedoManager,
  hideButtons = false
}: ITypeWellEditorHeader) {
  const [isTWRenamingFromHeader, setIsTWRenamingFromHeader] = useState(false);
  const [cloneTypeWellTitle, setCloneTypeWellTitle] = useState(``);
  const [cloneTypeWellOpen, setCloneTypeWellOpen] = useState(false);

  const { selectedProject } = useSelectedProject();
  const originalProject = useRef<string>(selectedProject?.projectId);
  const [cloneTypeWellToProjectId, setCloneTypeWellToProjectId] = useState<string>(
    selectedProject?.projectId
  );
  const [projects, setProjects] = useState<ProjectWithPermission[]>([]);
  const { resetTypeWell } = useTypeWellResetter(type, undoRedoManager);
  const deleteForecast = useDeleteForecast(type);
  const { isReadonly } = useUser();

  const projectPermissions = useSelectedProjectPermissions();

  const hasWellDataError = useSelector((state: RootState) => state.arps.hasWellDataError);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const projectListQuery = useProjectListQuery({
    onSuccess: (projects) => {
      const newProjects = projects.data.map((project) => {
        return {
          ...project,
          permission: projectPermissions
        };
      });

      setProjects(newProjects);
    }
  });

  const { onTypeWellValueChange } = useTypeWellUpdater(undoRedoManager);

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const selectedTypeWellTitle = selectedTypeWell?.title;
  const [typeWellEditorHeaderTitle, setTypeWellEditorHeaderTitle] = useState(
    selectedTypeWell?.title
  );
  const isTypeWellSavable = useSelector(
    (state: RootState) => state.arps.isTypeWellSavable
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // refs
  const cloneTypeWellInputRef = useRef(null);

  const isSync = !!selectedTypeWell?.source;

  const dispatch = useDispatch();
  const filterState = useSelector((state: RootState) => state.filter);

  useEffect(() => {
    dispatch(setIsTypeWellSavable(true));
  }, [filterState, dispatch]);

  useEffect(() => {
    if (selectedTypeWell) {
      setTypeWellEditorHeaderTitle(selectedTypeWell.title);
    }
  }, [selectedTypeWell?.title]);

  // The project selector should have the project you are in as the selected option when cloning a type well.
  useEffect(() => {
    setCloneTypeWellToProjectId(selectedProject?.projectId);
  }, [selectedProject?.projectId]);

  useEffect(() => {
    setIsTWRenamingFromHeader(false);
  }, [selectedTypeWell?.id]);

  // Update the copy type well text.
  useEffect(() => {
    if (selectedTypeWellTitle) {
      setCloneTypeWellTitle(`${selectedTypeWellTitle} Copy`);
    }
  }, [selectedTypeWellTitle]);

  // Handle the component initially loading.
  useEffect(() => {
    if (cloneTypeWellOpen && cloneTypeWellInputRef.current) {
      cloneTypeWellInputRef.current.select();
    }
  }, [cloneTypeWellOpen]);

  const handleCancelCloneTypeWell = useCallback(() => {
    setCloneTypeWellOpen(false);
    setCloneTypeWellTitle(`${selectedTypeWellTitle} Copy`);
  }, [selectedTypeWellTitle]);

  const handleCloneTypeWell = useCallback(() => {
    setCloneTypeWellOpen(false);

    handleClone &&
      handleClone(
        cloneTypeWellTitle,
        cloneTypeWellToProjectId !== originalProject.current
          ? {
              newProjectId: cloneTypeWellToProjectId
            }
          : null
      );
  }, [handleClone, cloneTypeWellToProjectId, cloneTypeWellTitle]);

  const Wrapper = isWidget ? WidgetRibbonWrapper : ProjectForecastsRibbonWrapper;

  /**
   * If the project ID where the type well will be saved isn't in the filtered projects,
   * this sets it to the first project in the filtered projects.
   * This can happen if the selected project is McDaniel (read-only) or if the user lacks permission to save to it.
   */
  useEffect(() => {
    if (projects.length === 0) return;

    const editableProjects = projects?.filter(
      (proj) => proj.permission?.canEditTypeWells
    );

    const isProjectInEditableProjects = editableProjects?.some(
      (proj) => proj.projectId === cloneTypeWellToProjectId
    );
    if (!isProjectInEditableProjects) {
      setCloneTypeWellToProjectId(editableProjects[0]?.projectId);
    }
  }, [projects, cloneTypeWellToProjectId]);

  if (cloneTypeWellOpen) {
    return (
      <Wrapper>
        <EditableText
          ref={cloneTypeWellInputRef}
          value={cloneTypeWellTitle}
          canedit={!isSync}
          bordered={true}
          onChange={(e) => setCloneTypeWellTitle(e.target.value)}
        />
        <Select
          data-testid="type-well-header-project-selector"
          value={cloneTypeWellToProjectId}
          onChange={(val) => {
            setCloneTypeWellToProjectId(val);
          }}>
          {(projects ?? [])
            .filter((proj) => proj.permission?.canEditTypeWells)
            .map((project) => {
              return (
                <Option
                  key={project.projectId}
                  value={project.projectId}
                  label={project.projectName}>
                  <OptionWrapper>
                    <span role="img">
                      {project.projectType == "Personal" ? (
                        <Icon path={mdiAccount} size={1.1} />
                      ) : project.projectType == "Shared" ? (
                        <Icon path={mdiAccountMultiple} size={1.1} />
                      ) : (
                        <Icon path={mdiDomain} size={1.1} />
                      )}
                    </span>
                    {project.projectName}
                  </OptionWrapper>
                </Option>
              );
            })}
        </Select>
        <Tooltip title="Cancel">
          <Button
            icon={<Icon path={mdiClose} size={1} color="#bbbbbb" />}
            type={"text"}
            onClick={handleCancelCloneTypeWell}
          />
        </Tooltip>
        <Tooltip title="Save As">
          <Button
            data-testid="type-well-editor-header-save-as-button"
            icon={<Icon path={mdiContentSavePlus} size={1} color="#02c7b0" />}
            type={"text"}
            onClick={handleCloneTypeWell}
          />
        </Tooltip>
      </Wrapper>
    );
  }

  const isDisabled = isSync || (!isWidget && !isEditTypeWellPanelOpen);
  const canEdit = !isSync && (isWidget || isEditTypeWellPanelOpen);

  const moreOptions = () => {
    return (
      <ActionListWrapper>
        <TypeWellAction
          data-testid="type-well-editor-header-rename-button"
          disabled={!projectPermissions.canEditTypeWells}
          onClick={() => {
            if (!projectPermissions.canEditTypeWells) return;

            setIsTWRenamingFromHeader(true);
          }}>
          <Icon path={mdiFormTextbox} size={1} />
          Rename
        </TypeWellAction>
        <Popconfirm
          placement="bottom"
          disabled={!projectPermissions.canEditTypeWells}
          onConfirm={(evt) => {
            deleteForecast.delete([
              {
                folderId: selectedTypeWell.folderId,
                id: selectedTypeWell.id,
                type: selectedTypeWell.type ?? "forecast"
              }
            ]);
            evt.stopPropagation();
            evt.preventDefault();
          }}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Delete"
          okType="danger"
          title={`Are you sure you want to delete the forecast`}>
          <TypeWellAction
            data-testid="type-well-editor-header-delete-button"
            disabled={!projectPermissions.canEditTypeWells}
            onClick={(evt) => {
              if (!projectPermissions.canEditTypeWells) return;

              evt.stopPropagation();
              evt.preventDefault();
            }}
            danger={true}>
            <Icon path={mdiDelete} size={1} />
            Delete
          </TypeWellAction>
        </Popconfirm>
        {projectPermissions.canEditTypeWells && (
          <SaveAsToggle
            setCloneTypeWellOpen={setCloneTypeWellOpen}
            setIsPopoverOpen={setIsPopoverOpen}
          />
        )}
        <ExportForecastsButton type={"selected"} wellSource={WellSource.TypeWells} />
      </ActionListWrapper>
    );
  };

  return (
    <Wrapper>
      {isTWRenamingFromHeader ? (
        <EditableText
          data-testid="type-well-editor-header-title-editable"
          value={typeWellEditorHeaderTitle}
          disabled={hideButtons || isDisabled || !canEdit}
          bordered={true}
          onChange={(e) => {
            setTypeWellEditorHeaderTitle(e.target.value);
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              e.preventDefault();
              if (selectedTypeWell && typeWellEditorHeaderTitle) {
                await handleSave(selectedTypeWell, typeWellEditorHeaderTitle);
                const typeWellWithUpdatedTitle = {
                  ...selectedTypeWell,
                  title: typeWellEditorHeaderTitle
                };
                onTypeWellValueChange(typeWellWithUpdatedTitle);
                setIsTWRenamingFromHeader(false);
              }
            }
          }}
        />
      ) : (
        <TitleWrapper data-testid="type-well-editor-header-title">
          {selectedTypeWellTitle}
        </TitleWrapper>
      )}
      {!hideButtons && (
        <>
          {!isSync && (
            <Tooltip title="Cancel">
              <Button
                disabled={!projectPermissions.canEditTypeWells}
                icon={<Icon path={mdiClose} size={1} color="#bbbbbb" />}
                type={"text"}
                onClick={() => {
                  setTypeWellEditorHeaderTitle(selectedTypeWellTitle);
                  resetTypeWell();
                  setIsTWRenamingFromHeader(false);
                }}
              />
            </Tooltip>
          )}
          {projectPermissions.canEditTypeWells && (
            <Tooltip title="Save">
              <span>
                <Button
                  data-testid="type-well-editor-header-save-button"
                  disabled={hasWellDataError || !projectPermissions.canEditTypeWells}
                  icon={
                    isTypeWellSavable ? (
                      <Icon
                        path={mdiContentSave}
                        size={1}
                        color={isSync ? "#bbbbbb" : "#02c7b0"}
                      />
                    ) : (
                      <Icon path={mdiCheck} size={1} color="#02c7b0" />
                    )
                  }
                  loading={saveStatus.loading}
                  type={"text"}
                  onClick={() => {
                    handleSave(selectedTypeWell, typeWellEditorHeaderTitle);
                    const typeWellWithUpdatedTitle = {
                      ...selectedTypeWell,
                      title: typeWellEditorHeaderTitle
                    };
                    onTypeWellValueChange(typeWellWithUpdatedTitle);
                    setIsTWRenamingFromHeader(false);
                  }}
                />
              </span>
            </Tooltip>
          )}
          {
            // User can still 'save as' to another project they do have permission for, even if they can't edit this type well.
            // Otherwise 'save as'  is in the 'more' menu.
            // Disabled for read only users, since they can't save anything
            !projectPermissions.canEditTypeWells && (
              <Tooltip title="Save As">
                <Button
                  disabled={isReadonly}
                  icon={<Icon path={mdiContentSavePlus} size={1} color="#02c7b0" />}
                  type={"text"}
                  onClick={() => {
                    setCloneTypeWellOpen(true);
                    setIsPopoverOpen(false);
                  }}
                />
              </Tooltip>
            )
          }
          {
            <Tooltip title="More">
              <Popover
                arrowPointAtCenter={true}
                onOpenChange={(isOpen) => {
                  setIsPopoverOpen(isOpen);
                }}
                content={moreOptions()}
                open={isPopoverOpen}
                overlayClassName="popover-no-padding"
                placement="bottomLeft"
                trigger="click">
                <Button
                  data-testid="type-well-editor-header-more-button"
                  icon={<Icon path={mdiDotsVertical} size={1} color="#a2aaad" />}
                  type={"text"}></Button>
              </Popover>
            </Tooltip>
          }
        </>
      )}
    </Wrapper>
  );
}

const ActionListWrapper = styled.div`
  padding: 6px;
`;

const ProjectForecastsRibbonWrapper = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  gap: 5px;

  .ant-select-selector {
    max-width: 150px;
    min-width: 150px;
  }

  .ant-btn-icon-only {
    width: 24px;
    height: 24px;
  }
`;

const WidgetRibbonWrapper = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  gap: 5px;
  overflow: hidden;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 6px;

  .ant-btn-icon-only {
    width: 24px;
    height: 24px;
  }
`;

const EditableText = styled(Input)`
  height: 25px;
  border: 1px solid black;
  background-color: white;
`;

const TitleWrapper = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    padding: 2px 5px 0;
  }
`;
