import { useQuery } from "react-query";

import axios from "axios";

import { USER_COLUMN_ENDPOINT } from "api/data";

export async function fetchGeomBinGroupOrder(isOrg: boolean) {
  try {
    const response = await axios.get(
      `${USER_COLUMN_ENDPOINT}/geombin/groupOrder/?isOrg=${isOrg ? "true" : "false"}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Unable to fetch geom bin group order");
  }
}

interface UseGeomBinGroupOrderQueryParamsT {
  isOrg: boolean;
  onError?: (error) => void;
  onSuccess?: (data) => void;
  isAutoRefetching?: boolean;
}

interface UseGeomBinGroupOrderQueryReturnT {
  data;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export function useGeomBinGroupOrderQuery(
  params: UseGeomBinGroupOrderQueryParamsT
): UseGeomBinGroupOrderQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true, isOrg } = params;

  const queryInfo = useQuery({
    queryKey: ["geomBinGroupOrder", isOrg],
    queryFn: () => fetchGeomBinGroupOrder(isOrg),
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching
  });

  return {
    data: queryInfo.data || [],
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
