import { useQuery } from "react-query";

import axios from "axios";
import { convertForecastConstantUnits } from "utils/arps/convertForecastConstantUnits";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { ForecastWell } from "../models/forecastWell";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1/well`;

export const FetchForecastWellQuery = "fetchForecastWell";
const backendProductToDisplayProduct = (product: string) => {
  switch (product.toLowerCase()) {
    case "total shrinkage inlet":
      return "SHRINKAGE";
    case "sales gas":
      return "SALES GAS";
    case "ethyieldsales":
      return "C2";
    case "propyieldsales":
      return "C3";
    case "butyieldsales":
      return "C4";
    default:
      return product;
  }
};
const fetchForecastWell = async (params) => {
  const uwid: string = params.queryKey[1];
  if (!uwid) {
    return null;
  }
  const response = await axios.get<ForecastWell | null>(`${API_URL}/${uwid}/forecast`);
  if (!response.data) {
    return null;
  }
  const data = response.data;
  if (!data.forecast) {
    data.forecast = {
      segments: [],
      constants: [],
      arps: []
    };
  }
  data.forecast.segments = data.forecast.segments.map((segment) => {
    segment.product = backendProductToDisplayProduct(segment.product);
    return segment;
  });
  data.forecast.constants = data.forecast.constants.map((segment) => {
    segment.product = backendProductToDisplayProduct(segment.product);
    return segment;
  });
  data.forecast.constants = convertForecastConstantUnits(data.forecast.constants);
  data.forecast = { ...data.forecast, arps: data.forecast?.segments };
  return data;
};

const useFetchForecastWell = (uwid: string) => {
  return useQuery<ForecastWell | null>(
    [FetchForecastWellQuery, uwid],
    fetchForecastWell,
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useFetchForecastWell;
